import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FilterData, WhereClause} from '../../../components/interfaces';
import {FieldSearch} from '../../../utils/utils';
import {CommonsGrid} from '../../../commons-grid';
import {ReportGroup} from '../../../shared/report-group';
import {Historicoprofissional} from "../historicoprofissional";
import {HistoricoProfissionalService} from "../historicoprofissional.service";
import * as moment from "moment";
import {NgxSmartModalService} from "ngx-smart-modal";
import {RodService} from "../../rod/rod.service";
import {Rod} from "../../rod/rod";

declare var $:any;


@Component({
  selector: 'app-historico-grid',
  templateUrl: './historico-grid.component.html',
   styleUrls: ['./historico-grid.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HistoricoGridComponent extends CommonsGrid<Historicoprofissional> implements OnInit {

    historicos: any;
    rods: Rod[];
    INSCRICAODEFINITIVA: [];
    TRANSFERENCIARECEBIDA: [];
    TRANSFERENCIAEMITIDA: [];
    REINSCRICAO: [];
    INSCRICAOSECRECEBIDA: [];
    INSCRICAOSECEMITIDA: [];
    APOSTILAMENTO: [];
    SEGVIA: [];
    BAIXA: [];
    PRORROGACAO: [];
    POSGRADUACAO: [];
    ESPECIALIZACAO: [];
    rodActive: Rod;
    lastRod: Rod;

     constructor(apiService: HistoricoProfissionalService,
                 router: Router,
                 public ngxModal: NgxSmartModalService,
                 private historicoservice: HistoricoProfissionalService,
                 private rodService: RodService) {
         super(apiService, router);
     }

     ngOnInit() {
       this.loadRodsByFilter(this.getDefaultFilter());
       this.getLastRod();

     }

  onsSelectRod(event){
       let id;
    let value = event.target.value;
    if(value.length == 7){
      id = value.substring(3)
    }else if (value.length == 8){
      id = value.substring(4)
    } else {
      id = value.substring(5)
    }
    this.loadRods(id);

  }

  loadRodsByFilter(filterData: FilterData) {
    this.rodService.loadByFilter(filterData).subscribe(response => {
      this.rods  = response.content;

    });
  }

  getLastRod(){

    this.apiService.getlastRod().subscribe(response => {
      this.lastRod = (<any>response);

      if (this.lastRod){
        this.rodActive = this.lastRod;
        this.loadRods(this.lastRod.id);
      }

    });

  }

     loadByFilter(filterData: FilterData) {

        this.apiService.loadByFilter(filterData).subscribe(response => {

        });
    }

    loadRods(rod: any){

      this.historicoservice.loadByRod(rod).subscribe(response => {
        this.historicos  = response;
        this.filterHistoricos();

});
}

  getTotal(item: string){
    if(this.historicos && this.historicos.length > 0) {

      switch (item) {
        case 'INSCRIÇÃO DEFINITIVA': {
            let valueDefinitivaAntigo = this.historicos.filter(t => t.historico == 'DEFINITIVA').length;
            return this.historicos.filter(t => t.historico == 'INSCRIÇÃO DEFINITIVA').length + valueDefinitivaAntigo;
        }

        case 'TRANSFERÊNCIA RECEBIDA': {
          return this.historicos.filter(t => t.historico == 'TRANSFERÊNCIA RECEBIDA').length;
        }

        case 'TRANSFERÊNCIA EMITIDA': {
          return this.historicos.filter(t => t.historico == 'TRANSFERÊNCIA EMITIDA').length;
        }

        case 'REINSCRIÇÃO': {
          return this.historicos.filter(t => t.historico == 'REINSCRIÇÃO').length;
        }

        case 'INSCRIÇÃO SEC RECEBIDA': {
          return this.historicos.filter(t => t.historico == 'INSCRIÇÃO SEC RECEBIDA').length;
        }

        case 'INSCRIÇÃO SEC EMITIDA': {
          return this.historicos.filter(t => t.historico == 'INSCRIÇÃO SEC EMITIDA').length;
        }

        case 'APOSTILAMENTO': {
          return this.historicos.filter(t => t.historico == 'APOSTILAMENTO').length;
        }

        case '2º VIA': {
          return this.historicos.filter(t => t.historico == '2º VIA').length;
        }

        case 'BAIXA': {
          return this.historicos.filter(t => t.historico == 'BAIXA').length;
        }

        case 'PRORROGAÇÃO': {
          return this.historicos.filter(t => t.historico == 'PRORROGAÇÃO').length;
        }

        case 'PÓS-GRADUAÇÃO': {
          return this.historicos.filter(t => t.historico == 'PÓS-GRADUAÇÃO').length;
        }

        case 'ESPECIALIZAÇÃO': {
          return this.historicos.filter(t => t.historico == 'ESPECIALIZAÇÃO').length;
        }

      }

    }

  }

  filterHistoricos(){
       this.INSCRICAODEFINITIVA =  this.historicos.filter(t => t.historico == 'INSCRIÇÃO DEFINITIVA' || t.historico == 'DEFINITIVA' );
        this.TRANSFERENCIARECEBIDA =  this.historicos.filter(t => t.historico == 'TRANSFERÊNCIA RECEBIDA');
        this.TRANSFERENCIAEMITIDA =  this.historicos.filter(t => t.historico == 'TRANSFERÊNCIA EMITIDA');
        this.REINSCRICAO =  this.historicos.filter(t => t.historico == 'REINSCRIÇÃO');
        this.INSCRICAOSECRECEBIDA =  this.historicos.filter(t => t.historico == 'INSCRIÇÃO SEC RECEBIDA');
        this.INSCRICAOSECEMITIDA =  this.historicos.filter(t => t.historico == 'INSCRIÇÃO SEC EMITIDA');
        this.APOSTILAMENTO =  this.historicos.filter(t => t.historico == 'APOSTILAMENTO');
        this.SEGVIA =  this.historicos.filter(t => t.historico == '2º VIA');
        this.BAIXA =  this.historicos.filter(t => t.historico == 'BAIXA');
        this.PRORROGACAO =  this.historicos.filter(t => t.historico == 'PRORROGAÇÃO');
        this.POSGRADUACAO =  this.historicos.filter(t => t.historico == 'PÓS-GRADUAÇÃO');
        this.ESPECIALIZACAO =  this.historicos.filter(t => t.historico == 'ESPECIALIZAÇÃO');
    }

    openResumo(){
      this.ngxModal.open('resumo-modal');
    }


     onNavigateClick(filterData: FilterData): void {
         this.loadByFilter(filterData);
     }

     getDefaultFilter(): FilterData {
         const filterData = super.getDefaultFilter();
         filterData.order = 'historico.bairro, historico.banco, historico.bancohistorico, historico.bancoconta, historico.cidade, historico.cnpj, historico.contato, historico.cpf, historico.datacadastro, historico.email, historico.email1, historico.email2, historico.emailcontato, historico.endereco, historico.fax, historico.inscricaoestadual, historico.inscricaomunicipal, historico.nomefantasia, historico.obs, historico.razaosocial, historico.site, historico.telefone, historico.telefone2 '
         return filterData;
     }

     getRowFilter(): FilterData {
         let filter= this.buildRowFilter('trb_historico');
         filter.whereClauses[0].dataType='INTEGER';
         return filter;
     }

     getTableName(): string {
         return 'historico' ;
     }

     getFieldList(): FieldSearch[] {
         let retorno = [];
         retorno[0] = new FieldSearch('bairro','bairro','STRING');

         return retorno;
     }
getReportList():ReportGroup[]{
    return [];
}

  ngAfterViewInit() {
    $('#historicotable').DataTable({
      pageLength: 10,
      fixedHeader: true,
      responsive: true,
      "sDom": 'rtip',
      columnDefs: [{
        targets: 'no-sort',
        orderable: false
      }]
    });

    var table = $('#historicotable').DataTable();
    $('#key-search').on('keyup', function() {
      table.search(this.value).draw();
    });
    $('#type-filter').on('change', function() {
      table.column(4).search($(this).val()).draw();
    });
  }


  closeModal() {
    this.ngxModal.close('resumo-modal');
  }
}
