import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import {ProfissionalService} from "../../entidades/profissional/profissional.service";
import {PessoaJuridicaService} from "../../entidades/pessoajuridica/pessoajuridica.service";
import {ConsultorioService} from "../../entidades/consultorio/consultorio.service";
import {SharedService} from "../../shared/shared.service";
import {Usuario} from "../../entidades/usuario/usuario";
import * as moment from "moment";
import {ProcessoAdministrativoService} from "../../entidades/processo-administrativo/processo-administrativo.service";
import {FilterDataHql} from "../../shared/filterdata/filter-data-hql";
import {ChartUtils} from "../../utils/chart-utils";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {ProtocoloAntingo} from "../../entidades/protocolo-antigo/protocolo-antingo";
import {ProtocoloAntigoService} from "../../entidades/protocolo-antigo/protocolo-antigo.service";
import {NgxSmartModalService} from "ngx-smart-modal";

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.componet.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  usuarioLogado: Usuario;

  totalProfissionais: number = 0;
  totalPessoasJuridicas: number = 0;
  totalCosultorio: number = 0;

  protocolos: Array<ProtocoloAntingo> = [];

  profissionais: Array<number> = new Array<number>();
  pessoasjuridicas: Array<number> = new Array<number>();
  consultorios: Array<number> = new Array<number>();

  pfMap: Map<string, number> = new Map<string, number>();
  pjMap: Map<string, number> = new Map<string, number>();
  ctMap: Map<string, number> = new Map<string, number>();

  isBanner:boolean;

  constructor(private scriptPlugins: ScriptLoaderService,
              public sharedService: SharedService,
              public profissionalService: ProfissionalService,
              public pjService: PessoaJuridicaService,
              public consultrioService: ConsultorioService,
              public toastService: ToastrService,
              public modalService: NgbModal,
              public ngxModal: NgxSmartModalService,
              public processoAdministrativoService: ProcessoAdministrativoService,
              public protocoloService: ProtocoloAntigoService) { }

  ngOnInit() {
    this.isBanner = sessionStorage.getItem('isBanner') as unknown as boolean;
    this.sharedService.updateLoginDetails();
    this.usuarioLogado = this.sharedService.loginResponse.usuario;

    this.loadContent();
    //this.loadProtocolos();
    this.loadMapValues();
  }

  ngAfterViewInit() {
    this.scriptPlugins.load('./assets/js/scripts/widgets.js').then();
    //this.loadChart();
    //ChartUtils.loadEasyPie();
    if(this.isBanner) {
      // this.ngxModal.open('modal-banner');
      // sessionStorage.removeItem('isBanner');
    }
  }

  loadContent() {
    this.profissionalService.getCountTotal().subscribe(response => {
      if(response) {
        this.totalProfissionais = response;

        this.pjService.getCountTotal().subscribe(response => {
          if(response) {
            this.totalPessoasJuridicas = response;

            this.consultrioService.getCountTotal().subscribe(response => {
              if(response) {
                this.totalCosultorio = response;

                this.loadInscritos();
              }

            }, error => console.log(error.message))
          }
        }, error => console.log(error.message));
      }
    }, error => console.log(error.message));
  }

  loadMapValues() {
    this.profissionalService.getCountValues().subscribe(response => {
      if(response) {
        const values: Array<any> = response;
        values.forEach(v => this.pfMap.set(v.nome, v.qtd));

        //console.log(response);
        //console.log(values);
      }
    }, error => console.log(error.message));

    this.pjService.getCountValues().subscribe(response => {
      if(response) {
        const values: Array<any> = response;
        values.forEach(v => this.pjMap.set(v.nome, v.qtd));

        console.log('PJ');
        console.log(response);
        console.log(values);
      }

    }, error => console.log(error.message));

    this.consultrioService.getCountValues().subscribe(response => {
      if(response) {
        const values: Array<any> = response;
        values.forEach(v => this.ctMap.set(v.nome, v.qtd));

        console.log('Consultório');
        console.log(response);
        console.log(values);
      }

    }, error => console.log(error.message));
  }

  loadProcessosExpired() {
    /*this.modalService.open( {size: 'lg'});*/

    /*this.processoAdministrativoService.loadByFilterHql({} as FilterDataHql).subscribe(response => {
      if(response) {
        this.modalService.open('processosExpiredsModal');
      }

    }, error => {
      console.log(error.message);
    });*/
  }

  loadProtocolos() {
    if(this.usuarioLogado) {
      this.protocoloService.loadByUsuario(this.usuarioLogado.login).subscribe(response => {
        if(response) {
          this.protocolos = <any>response;
          this.protocolos = this.protocolos.sort((p1, p2) => p1.datatime < p2.datatime ? 1 : -1);

          this.loadProcessosExpired();
        }

      }, error => {
        console.log(error.message);

      });
    }
  }

  getTempoCorrido(d: Date): string {
    if(d) {
      const diferenca = moment.duration(moment(new Date()).diff(moment(d)));

      //let seconds = diferenca;
      //let minutes = diferenca / 60;
      //let hours = diferenca / 3600;
      //let days = diferenca / 86400;
      //let weeks = diferenca / 604800;
      //let months = diferenca / 2419200;
      //let years = diferenca / 29030400;

      let seconds = diferenca.asSeconds();
      let minutes = diferenca.asMinutes();
      let hours = diferenca.asHours();
      let days = diferenca.asDays();
      let weeks = diferenca.asWeeks();
      let months = diferenca.asMonths();
      let years = diferenca.asYears();

      if (seconds <= 60) return "1 min atrás";
      else if (minutes <= 60) return minutes == 1 ? '1 min atrás' : Math.round(minutes) + ' min atrás';
      else if (hours <= 24) return hours == 1 ? '1 hrs atrás' : Math.round(hours) + ' hrs atrás';
      else if (days <= 7) return days == 1 ? '1 dia atras' : Math.round(days) + ' dias atrás';
      else if (weeks <= 4) return weeks == 1 ? '1 semana atrás' : Math.round(weeks) + ' semanas atrás';
      else if (months <= 12) return months == 1 ? '1 mês atrás' : Math.round(months) + ' meses atrás';
      else return years == 1 ? 'um ano atrás': Math.round(years) + ' anos atrás';
    }

    return "0";
  }

  loadInscritos(): void {
    this.profissionalService.getCountTotalFromMonths().subscribe((response) => {
      if(response) {
        this.profissionais = response;
        //console.log('PF:=>' + response);

        this.pjService.getCountTotalFromMonths().subscribe(response => {
          if(response) {
            this.pessoasjuridicas = response;
            //console.log('PJ:=>' + response);

            this.consultrioService.getCountTotalFromMonths().subscribe(response => {
              if(response) {
                this.consultorios = response;
                //console.log('CT:=>' + response);

                this.loadChart();
              }

            }, error => console.log(error.message));

          }
        }, error => console.log(error.message));
      }

    }, error => console.log(error.message));
  }

  loadChart(): void {
    let labels = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    const content = this.getContent();
    const hoje = moment().clone();
    const month = hoje.get('month');

    if(month > 2) {
      (<any>content).labels = [labels[month - 3], labels[month - 2], labels[month - 1], labels[month]];

    } else {
      if(month == 2) {
        (<any>content).labels = [labels[11], labels[month - 2], labels[month - 1], labels[month]];

      } else if(month == 1) {
        (<any>content).labels = [labels[10], labels[11], labels[month - 1], labels[month]];

      } else {
        (<any>content).labels = [labels[9], labels[10], labels[11], labels[month]];
      }
    }

    (<any>content).datasets[0].data = this.profissionais.reverse();
    (<any>content).datasets[1].data = this.pessoasjuridicas.reverse();
    (<any>content).datasets[2].data = this.consultorios.reverse();


    ChartUtils.loadChart('chart-profissionais', 'bar', content);
  }

  getContent(): {} {
    return {
      labels: ['Fevereiro', 'Março', 'Abril', 'Maio'],
      datasets: [
        {
          label: "Profissionais",
          borderColor: 'rgba(117,54,230,0.9)',
          backgroundColor: 'rgba(117,54,230,0.9)',
          borderWidth: 1,
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          data: []
        },
        {
          label: "Pessoas Jurídicas",
          borderColor: 'rgba(104,218,221,1)',
          backgroundColor: 'rgba(104,218,221,1)',
          borderWidth: 1,
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          data: []
        },
        {
          label: "Consultórios",
          borderColor: '#DA70D6',
          backgroundColor: '#DA70D6',
          borderWidth: 1,
          barPercentage: 0.7,
          categoryPercentage: 0.5,
          data: []
        }
      ]
    }
  }

  getPercentualPF(key: string): number {
    const qtd = this.pfMap.get(key);
    if(qtd) {
      return (qtd * 100) / this.totalProfissionais
    }

    return 0.00;
  }

  getPercentualPJ(key: string): number {
    const qtd = this.pjMap.get(key);
    if(qtd) {
      return (qtd * 100) / this.totalPessoasJuridicas
    }

    return 0.00;
  }

  getPercentualCT(key: string): number {
    const qtd = this.ctMap.get(key);
    if(qtd) {
      return (qtd * 100) / this.totalCosultorio;
    }

    return 0.00;
  }

  getTotal(): number {
    return this.totalProfissionais + this.totalPessoasJuridicas + this.totalCosultorio;
  }
}
