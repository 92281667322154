import { RouterModule,Routes } from '@angular/router';

import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {HistoricoProfissionalGridComponent} from './historicoprofissional-grid/historicoprofissional-grid.component';
import {HistoricoProfissionalFormComponent} from './historicoprofissional-form/historicoprofissional-form.component';
import { ReactiveFormsModule } from '@angular/forms';

import {CobrancaModule} from '../cobranca/cobranca.module';
import {HistoricoProfissionalComponent} from "./historicoprofissional.component";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { DataTablesModule } from 'angular-datatables';
import {HistoricoProfissionalViewComponent} from "./historicoprofissional-view/historicoprofissional-view.component";
import {AuthGuard} from "../../security/auth.guard";
import {HistoricoGridComponent} from "./historico-grid/historico-grid.component";
import {NgxSmartModalModule} from "ngx-smart-modal";


const routes: Routes = [
  { path: '', component: HistoricoGridComponent, canActivate: [AuthGuard] },

];


        @NgModule({
                imports: [RouterModule.forChild(routes),
                SharedModule ,
                CobrancaModule,
                ReactiveFormsModule,
                DataTablesModule,
                  NgxSmartModalModule.forRoot(),
                NgbModule
                ],
                declarations: [
                        HistoricoProfissionalGridComponent,HistoricoProfissionalFormComponent, HistoricoProfissionalComponent,HistoricoProfissionalViewComponent, HistoricoGridComponent
                ],
                entryComponents: [HistoricoProfissionalFormComponent,HistoricoProfissionalComponent,HistoricoProfissionalGridComponent,HistoricoProfissionalViewComponent],
                schemas: [],
                providers: [] ,
          exports:[
            HistoricoProfissionalGridComponent
          ]
                })
        export class HistoricoProfissionalModule {}


