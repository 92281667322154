import { Injectable } from '@angular/core';
import {CommonsService, ServerResponse} from "../../commons-service";
import {RetornoBoleto} from "./retorno-boleto";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RetornoBoletoService extends CommonsService<RetornoBoleto> {
  getPathModule(): string {
    return "retornoboleto";
  }

  public loadByCobranca(id: string): Observable<ServerResponse<RetornoBoleto>> {
    return this.http.get<ServerResponse<RetornoBoleto>>(`${this.API_ROOT_URL}/${this.getPathModule()}/cobranca/${id}`);
  }
}
