import { Injectable } from '@angular/core';
import {CommonsService, ServerResponse} from "../../commons-service";
import {ProcessoAdministrativo} from "./processo-administrativo";
import {Observable} from "rxjs";
import {FilterDataHql} from "../../shared/filterdata/filter-data-hql";
import {WhereClauseHql} from "../../shared/filterdata/where-clause-hql";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class ProcessoAdministrativoService extends CommonsService<ProcessoAdministrativo> {

  getPathModule(): string {
    return 'processoadministrativo';
  }

  public loadGuias(guiaId: string): Observable<ServerResponse<ProcessoAdministrativo>> {
    return this.http.get<ServerResponse<ProcessoAdministrativo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/loadguias/${guiaId}`);
  }

  public loadFromGrafica(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/loadfromgrafica`);
  }

  public loadAllRegistrosGrafica(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/controle-grafica`);
  }

  public registrarEnvioGrafica(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/controle-grafica/registrar-envio`);
  }

  public getFases(): Array<{label: string, value: string}> {
    return [
      {label: 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA', value: 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA'},
      {label: 'DESPACHO', value: 'DESPACHO'},
      {label: 'ENVIO À GRÁFICA', value: 'ENVIO À GRÁFICA'},
      {label: 'AR POSTADO', value: 'AR POSTADO'},
      {label: 'AR ENTREGUE', value: 'AR ENTREGUE'},
      {label: 'AR DEVOLVIDO', value: 'AR DEVOLVIDO'},
      {label: 'EMISSÃO DA CDA', value: 'EMISSÃO DA CDA'},
      {label: 'FINALIZADO POR PAGAMENTO', value: 'FINALIZADO POR PAGAMENTO'},
      {label: 'COBRANÇA INDEVIDA', value: 'COBRANÇA INDEVIDA'},
      {label: 'NOTIFICAÇÃO POR EDITAL', value: 'NOTIFICAÇÃO POR EDITAL'},
      {label: 'INSCRIÇÃO EM DÍVIDA ATIVA ADMINISTRATIVA', value: 'INSCRIÇÃO EM DÍVIDA ATIVA ADMINISTRATIVA'},
      {label: 'INSCRIÇÃO EM DÍVIDA ATIVA', value: 'INSCRIÇÃO EM DÍVIDA ATIVA'},
    ];
  }

  public getFiltroDefault(): FilterDataHql {
    return { whereClauses: new Array<WhereClauseHql>() } as FilterDataHql;
  }

  public upaloadArquivoRetornoGrafica(form: FormData): Observable<any> {
    return this.http.post(`${this.API_ROOT_URL}/${this.getPathModule()}/controle-grafica/upload-retorno`, form);
  }

  public montarArquivoGrafica():Observable<{} | Blob> {
    return this.http.get(`${this.API_ROOT_URL}/${this.getPathModule()}/controle-grafica/construir-csv`, {responseType: 'blob'}).pipe(
      map(response => {
        if(response) {
          return new Blob([response as any], {type: 'text/csv'});
        }
        return null;
      }),
      catchError((error) => {
        console.log(error);
        return error;
      })
    );
  }
}
