import {CommonsService, ServerResponse} from '../../commons-service';
import {ProtocoloAntingo} from "./protocolo-antingo";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";


@Injectable({ providedIn: 'root' })
export class ProtocoloAntigoService extends CommonsService<ProtocoloAntingo> {

  getPathModule(): string {
    return 'protocoloantingo';
  }

  public loadByUsuario(id: string): Observable<ServerResponse<ProtocoloAntingo>> {
    return this.http.get<ServerResponse<ProtocoloAntingo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/usuario/${id}`);
  }
}
