import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { Livroto} from './livroto'

@Injectable({ providedIn: 'root' }) 
export class LivrotoService extends CommonsService<Livroto> {

     getPathModule(): string { 
         return 'livroTo';
     } 
} 
