import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler) {
   let token = sessionStorage.getItem("token");
    if (token) {
      if(req.url.includes('upload/arquivo') || req.url.includes('/upload') || req.url.includes('bb/arquivo-bbt')) {
        req = req.clone({
          setHeaders: {
            'Authorization': token
          }
        })
      } else if(req.url.includes('google')) {
        return next.handle(req);
      } else {
        req = req.clone({
          setHeaders: {
            'Authorization': token,
            'Content-type': 'application/json; charset=utf8'
          }
        });
      }
    }
    return next.handle(req);

  }

}
