import {CommonsService} from '../../commons-service'; 
import {Injectable} from "@angular/core";
import {Cda} from "./cda";


@Injectable({ providedIn: 'root' }) 
export class CdaService extends CommonsService<Cda> {

     getPathModule(): string { 
         return 'cda';
     } 
} 
