import {Component, OnInit} from '@angular/core';
import {FilterDataHql} from "../../shared/filterdata/filter-data-hql";
import {WhereClauseHql} from "../../shared/filterdata/where-clause-hql";
import {SharedService} from "../../shared/shared.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {ColumnType} from "../../shared/filterdata/column-type.enum";
import {ColumnMatch} from "../../shared/filterdata/column-match.enum";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss']
})
export class RelatorioComponent implements OnInit {

  relatoriosSelecionado: string;
  relatoriosDefisSelecionado: string;
  rpt: {title: string, name: string, filtro?: FilterDataHql};
  relatorios: Array<{title: string, name: string, filtro?: FilterDataHql}>
  relatoriosDefis: Array<{title: string, name: string, filtro?: FilterDataHql}>

  constructor(public sharedService: SharedService,
              public toastService: ToastrService,
              public ngxModal: NgxSmartModalService) {
  }

  ngOnInit() {
    this.loadRelatorios();
  }

  loadRelatorios() {
    this.relatorios = [
      {title: 'Listagem Sintética', name: 'ListagemSinteticaProfissional', filtro: this.getFiltros('ListagemSinteticaProfissional')},
      {title: 'Listagem Sintética de Devedores Por Anuidade', name: 'ListagemSinteticaDevedoresPorAnuidade', filtro: this.getFiltros('ListagemSinteticaDevedoresPorAnuidade')},
      {title: 'Quantitativo Por Graduação', name: 'QuantitativoProfissionalPorGraduacao', filtro: this.getFiltros('QuantitativoProfissionalPorGraduacao')},
      {title: 'Quantitativo Por Cidade', name: 'QuantitativoProfissionalPorCidade', filtro: this.getFiltros('QuantitativoProfissionalPorCidade')},
      {title: '', name: ''},
      {title: '', name: ''},
      {title: '', name: ''},
      {title: '', name: ''},
      {title: '', name: ''},
    ];
    this.relatoriosDefis = [
      {title: 'Quantitativo Fiscalizatório', name: 'QuantitativoFiscalizatorioPorAgente', filtro: this.getFiltros('QuantitativoFiscalizatorioPorAgente')},
      {title: '', name: ''},
      {title: '', name: ''},
    ];
  }

  getFiltros(name: string): FilterDataHql {
    const f = {whereClauses: []} as FilterDataHql;
    switch (name) {
      case 'ListagemSinteticaProfissional': {
        f.whereClauses.push({canUse: false, label: 'Dt. de Cadastro', columnName: 'P.data_cadastro', iniValue: '', columnType: ColumnType.DATE, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Graduação', columnName: 'P.graduacao', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Sexo', columnName: 'P.sexo', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Cidade', columnName: 'P.cidade', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'UF', columnName: 'P.estado', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Situação', columnName: 'S.descricao', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        return f;
      }
      case 'QuantitativoProfissionalPorGraduacao': {
        f.whereClauses.push({canUse: false, label: 'Sexo', columnName: 'P.sexo', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Cidade', columnName: 'P.cidade', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'UF', columnName: 'P.estado', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Situação', columnName: 'S.descricao', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        return f;
      }
      case 'ListagemSinteticaDevedoresPorAnuidade': {
        f.whereClauses.push({required: true, canUse: true, label: 'Anuidade', columnName: 'anuidade', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Sexo', columnName: 'P.sexo', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Cidade', columnName: 'P.cidade', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Situação', columnName: 'S.descricao', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        return f;
      }
      case 'QuantitativoProfissionalPorCidade': {
        f.whereClauses.push({canUse: false, label: 'Sexo', columnName: 'P.sexo', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Cidade', columnName: 'P.cidade', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'UF', columnName: 'P.estado', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        f.whereClauses.push({canUse: false, label: 'Situação', columnName: 'S.descricao', iniValue: '', columnType: ColumnType.STRING, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
        return f;
      }
      default : return f;
    }
  }

  onClickPrint(tipo?: string) {
    if(!tipo && this.relatoriosSelecionado) {
      this.rpt = this.relatorios.find(r => r.name == this.relatoriosSelecionado);
      // console.log(this.rpt);
      if(this.rpt.filtro.whereClauses.length) {
        this.ngxModal.open('modal-filter');
      } else {
        const filter = this.rpt.filtro;
        filter.whereClauses = this.rpt.filtro.whereClauses.filter(wc => wc.canUse);
        this.sharedService.printFromFilter(this.rpt.name, filter);
      }
    }

    if(tipo == 'DEFIS' && this.relatoriosDefisSelecionado) {
      this.rpt = this.relatoriosDefis.find(r => r.name == this.relatoriosDefisSelecionado);
      if(this.rpt.filtro.whereClauses.length) {
        this.ngxModal.open('modal-filter');
      } else {
        const filter = this.rpt.filtro;
        filter.whereClauses = this.rpt.filtro.whereClauses.filter(wc => wc.canUse);
        this.sharedService.printFromFilter(this.rpt.name, filter);
      }
    }
  }

  Imprimir() {
    const filter = {whereClauses: []} as FilterDataHql;
    filter.whereClauses = this.rpt.filtro.whereClauses.filter(wc => wc.canUse);
    const existeRequerido = filter.whereClauses.find(wc => wc.required && (!wc.iniValue.length || wc.iniValue.length != 4));
    if(!existeRequerido) {
      this.sharedService.printFromFilter(this.rpt.name, filter);
    } else {
      this.toastService.error('Campo obrigatório não informado.', 'Atenção!!!!');
    }
  }

  onCloseModalEvent() {
    this.relatoriosSelecionado = null;
    this.rpt = null;
    this.loadRelatorios();
  }
}
