import { Injectable } from '@angular/core';
import {CommonsService} from "../../commons-service";
import {AutoPecunia} from "./auto-pecunia";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AutoPecuniaService extends CommonsService<AutoPecunia> {

  constructor(public http: HttpClient) { super(http) }

  getPathModule(): string {
    return "autopecunia";
  }
}
