import {CommonsService} from '../../commons-service'; 
import {Injectable} from "@angular/core";
import {Guia} from './guia'; 
import {DomainBase} from '../../utils/utils';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {map} from "rxjs/operators";
import * as stringifyCustom from 'json-stringify-safe';
import {environment} from "../../../environments/environment";

export class ServerResponse<T extends DomainBase> {
    content: any;
    success: boolean;
    message: string;
  }


@Injectable({ providedIn: 'root' }) 
export class GuiaService extends CommonsService<Guia> { 

    
  protected API_ROOT_URL = environment.getBaseURL();
  reportName = "";


     getPathModule(): string { 
         return 'guia'; 
     } 


  public getbyCpad(prof): Observable<ServerResponse<Guia>> {
    return this.http.post<ServerResponse<Guia>>(`${this.API_ROOT_URL}/${this.getPathModule()}/getbycpad`, prof );
  }
} 
