import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonsForm} from '../../../commons-form';
import {FilterData} from '../../../components/interfaces';
import {CommonsService} from '../../../commons-service';
import {HistoricoProfissionalService} from '../historicoprofissional.service';

import { ToastrService } from 'ngx-toastr';


import {IndiceService} from '../../indice/indice.service';

import { ScriptLoaderService } from '../../../_services/script-loader.service';
declare var $:any;

import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../../../shared/shared.service";
import {Historicoprofissional} from "../historicoprofissional";


@Component({
 selector: 'gov-historicoprofissional-view',
 templateUrl: './historicoprofissional-view.component.html',
 //styleUrls: ['./historicoProfissional-form.component.css']
})
export class HistoricoProfissionalViewComponent extends CommonsForm<Historicoprofissional> implements OnInit {

     @Input() historicoprofissionalView;

    constructor(apiService: HistoricoProfissionalService,
                route: ActivatedRoute,
                private fb: FormBuilder,
                private sharedService: SharedService,
                public activeModal: NgbActiveModal,
                private indiceService: IndiceService,
                private _script: ScriptLoaderService,
                private toastr: ToastrService,
 router: Router ) {
         super(apiService, route, router);
     }

     ngOnInit() {

  console.log(this.historicoprofissionalView);
     }















  printCertificado(){
    if (this.historicoprofissionalView.historico == "INSCRIÇÃO DEFINITIVA"){
      this.printCertificadoByName("padinscricaodefinitiva")
    }
      if (this.historicoprofissionalView.historico == "INSCRIÇÃO SEC RECEBIDA"){
        this.printCertificadoByName("padinssecrecebida")
      }
    if (this.historicoprofissionalView.historico == "PRORROGAÇÃO"){
      this.printCertificadoByName("padprorrogacao")
    }
    if (this.historicoprofissionalView.historico == "PÓS-GRADUAÇÃO"){
      this.printCertificadoByName("padgraduacao")
    }
    if (this.historicoprofissionalView.historico == "TRANSFERÊNCIA"){
      this.printCertificadoByName("padtransferencia")
    }
    if (this.historicoprofissionalView.historico ==  "BAIXA"){
      this.printCertificadoByName("padbaixa")
    }
    if (this.historicoprofissionalView.historico == "APOSTILAMENTO"){
      this.printCertificadoByName("padapostilamento")
    }
    if (this.historicoprofissionalView.historico == "REINSCRIÇÃO"){
      this.printCertificadoByName("padreinscricao")
    }
    if (this.historicoprofissionalView.historico == "INSCRIÇÃO SEC EMITIDA"){
      this.printCertificadoByName("padinscsecemitida")
    }
    if (this.historicoprofissionalView.historico == "ESPECIALIZAÇÃO"){
      this.printCertificadoByName("padesécializacao")
    }
    if (this.historicoprofissionalView.historico == "TRANSFERÊNCIA EMITIDA"){
      this.printCertificadoByName("padtransfemitida")
    }
    if (this.historicoprofissionalView.historico == "TRANSFERÊNCIA RECEBIDA"){
      this.printCertificadoByName("padtransfrecebida")
    }
    if (this.historicoprofissionalView.historico == "2º VIA"){
      this.printCertificadoByName("pad2via")
    }
  }

  printCertificadoByName(relatorioName) {

    this.sharedService.printReport( relatorioName, this.activeBean.id);
  }

     getLookupService(lookupName: string): CommonsService<any> {
         switch (lookupName) {

             default: {
                 console.log('serviço não disponibilizado para ', lookupName)
                 return this.apiService;
             }
         }
     }

     getDeLookupFilter(lookupValue: any): FilterData {
         switch (lookupValue.name) {
             case 'uf': {
                 return this.buildLookupFilter('uf');
             }

             default: {
                 console.log('filtro não disponibilizado para ', lookupValue.name)
             }
         }
         return null;
     }

     getDefaultFilter(): FilterData {
        const filterData = super.getDefaultFilter();
        return filterData;
    }

}

