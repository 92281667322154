export enum ColumnMatch {
  EQUALS,
  START_WITH,
  END_WITH,
  LIKE,
  NOT_LIKE,
  LIKE_IN,
  BETWEEN,
  NOT_EQUALS,
  IN,
  NOT_IN,
  LESS_THAN,
  LESS_OR_EQUAL,
  GREAT_THAN,
  GREAT_OR_EQUAL,
  ISNULL,
  NOTNULL
}
