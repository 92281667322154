import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from "./security/auth.guard";
import {LoginComponent} from "./security/login/login.component";
import {LogoutComponent} from "./security/logout/LogoutComponent";
import {LayoutComponent} from './layouts/layout.component';

import {HomeComponent} from './pages/home/home.component';
import {Error404Component} from './pages/error-404/error-404.component';
import {Error500Component} from './pages/error-500/error-500.component';
import {CommonModule} from "@angular/common";
import {SolicitacaoCertidaoValidacaoComponent} from "./entidades/solicitacao-certidao/solicitacao-certidao-validacao/solicitacao-certidao-validacao.component";
import {NgxSmartModalModule} from "ngx-smart-modal";
import {RelatorioComponent} from "./pages/relatorio/relatorio.component";


const routes: Routes = [
  {path: '', redirectTo: 'index', pathMatch: 'full'},

  {path: 'login', component: LoginComponent},
  {path: 'validarcertidao/profissional/:id/:hashcode', component: SolicitacaoCertidaoValidacaoComponent},
  {path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
  {
    "path": "",
    "component": LayoutComponent,
    canActivate: [AuthGuard],
    "children": [
      {path: 'eleicao', loadChildren: './entidades/eleicao/eleicao.module#EleicaoModule'},
      {path: 'profissional', loadChildren: './entidades/profissional/profissional.module#ProfissionalModule'},
      {path: 'pessoajuridica', loadChildren: './entidades/pessoajuridica/pessoajuridica.module#PessoaJuridicaModule'},
      {path: 'consultorio', loadChildren: './entidades/consultorio/consultorio.module#ConsultorioModule'},
      {path: 'chamado', loadChildren: './entidades/chamado/chamado.module#ChamadoModule'},
      {path: 'tiposcobranca', loadChildren: './entidades/tipocobranca/tipocobranca.module#TipoCobrancaModule'},
      {path: 'indice', loadChildren: './entidades/indice/indice.module#IndiceModule'},
      {path: 'cda', loadChildren: './entidades/cda/cda.module#CdaModule'},
      {path: 'usuario', loadChildren: './entidades/usuario/usuario.module#UsuarioModule'},
      {path: 'decob', loadChildren: './entidades/decob/decob.module#DecobModule'},
      {path: 'atendimento', loadChildren: './entidades/atendimento/atendimento.module#AtendimentoModule'},
      {path: 'defis', loadChildren: './entidades/defis/defis.module#DefisModule'},
      {path: 'etica', loadChildren: './entidades/etica/etica.module#EticaModule'},
      {path: 'processo-etico', loadChildren: './entidades/processo-etico/processo-etico.module#ProcessoEticoModule'},
      {path: 'controle-tac', loadChildren: './entidades/controle-tac/controle-tac.module#ControleTacModule'},
      {path: 'projur', loadChildren: './entidades/projur/projur.module#ProjurModule'},
      {path: 'registro', loadChildren: './entidades/registro/registro.module#RegistroModule'},
      {path: 'secretaria', loadChildren: './entidades/secretaria/secretaria.module#SecretariaModule'},
      {path: 'rod', loadChildren: './entidades/rod/rod.module#RodModule'},
      {path: 'licitacao', loadChildren: './entidades/licitacao/licitacao.module#LicitacaoModule'},
      {path: 'evento', loadChildren: './entidades/evento/evento.module#EventoModule'},
      {
        path: 'consultarod',
        loadChildren: './entidades/historicoprofissional/historicoprofissional.module#HistoricoProfissionalModule'
      },
      {path: 'termovisita', loadChildren: './entidades/termovisita/termovisita.module#TermovisitaModule'},
      {
        path: 'processo-fiscalizacao',
        loadChildren: './entidades/processo-fiscalizacao/processo-fiscalizacao.module#ProcessoFiscalizacaoModule'
      },
      {path: 'log', loadChildren: './entidades/log/log.module#LogModule'},
      {path: 'consolidacao', loadChildren: './entidades/consolidacao/consolidacao.module#ConsolidacaoModule'},
      {path: 'importdata', loadChildren: './entidades/importdata/importdata.module#ImportdataModule'},
      {path: 'protocolo', loadChildren: './entidades/protocolo/protocolo.module#ProtocoloModule'},
      {
        path: 'instituicao-ensino',
        loadChildren: './entidades/instituicao-ensino/instituicao-ensino.module#InstituicaoEnsinoModule'
      },
      {path: 'prestador', loadChildren: './entidades/prestador/prestador.module#PrestadorModule'},
      {
        path: 'controle-oficio',
        loadChildren: './entidades/controle-oficio/controle-oficio.module#ControleOficioModule'
      },
      {
        path: 'controle-serpro',
        loadChildren: './entidades/controle-serpro/controle-serpro.module#ControleSerproModule'
      },
      {
        path: 'desconhecido',
        loadChildren: './entidades/desconhecido/desconhecido.module#DesconhecidoModule'
      },
      {
        path: "index",
        component: HomeComponent
      },
      {
        path: "relatorios",
        component: RelatorioComponent
      }
    ]
  },
  {
    "path": "login",
    "component": LoginComponent
  },
  {
    "path": "error_404",
    "component": Error404Component
  },

  {
    "path": "error_500",
    "component": Error500Component
  },
  {
    "path": "**",
    "redirectTo": "error_404",
    "pathMatch": "full"
  },
];


@NgModule({
  declarations: [
    HomeComponent,
    Error404Component,
    Error500Component,

  ],
  imports: [RouterModule.forRoot(routes, {useHash: true}), CommonModule, NgxSmartModalModule],
  exports: [
    RouterModule,
  ]
})

export class AppRoutingModule {
}
