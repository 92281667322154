import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessoAdministrativoViewComponent } from './processo-administrativo-view/processo-administrativo-view.component';
import {NgxSmartModalModule} from "ngx-smart-modal";
import {FormsModule} from "@angular/forms";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import { ProcessoAdministrativoFormComponent } from './processo-administrativo-form/processo-administrativo-form.component';
import { ProcessoAdministrativoFaseModalComponent } from './processo-administrativo-fase-modal/processo-administrativo-fase-modal.component';

@NgModule({
  declarations: [ProcessoAdministrativoViewComponent, ProcessoAdministrativoFormComponent, ProcessoAdministrativoFaseModalComponent],
  imports: [
    CommonModule,
    NgxSmartModalModule,
    FormsModule,
    NgbDatepickerModule
  ],
  exports: [ProcessoAdministrativoViewComponent, ProcessoAdministrativoFormComponent, ProcessoAdministrativoFaseModalComponent]
})
export class ProcessoAdministrativoModule { }

