import {LoginRequest} from '../login-request';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SHA256} from "../../utils/Sha256";
import {environment} from "../../../environments/environment";


@Injectable()
export class LoginService {

  constructor(private http: HttpClient) {}

  login(user: LoginRequest){
    //user.password = new SHA256().hash(user.password);
    //return this.http.post(`https://crefitonet.herokuapp.com/api/auth`,user);
    return this.http.post(`${environment.getBaseURL()}/api/auth`,user);
  }

}
