import {LoginResponse} from "../security/login-response";
import {Inject, Injectable} from "@angular/core";
import {FilterData, WhereClause} from "../components/interfaces";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { DOCUMENT } from '@angular/platform-browser';
import {DataType, FieldSearch, MatchType} from "../utils/utils";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import Swal from "sweetalert2";
import * as moment from "moment";
import {FilterDataHql} from "./filterdata/filter-data-hql";
import {NgxSpinnerService} from "ngx-spinner";


@Injectable()
export class SharedService {


  public loginResponse:LoginResponse;
  public isLogged: boolean;
  public theme:string = "";

  public static beanIdSelected: any;

  constructor(private http: HttpClient,@Inject(DOCUMENT) private document,
              private spinnerService: NgxSpinnerService) {
    this.updateLoginDetails();

  }

  downloadBoletoUrl(url): Observable<any> {
    return this.http.get(url, {responseType: 'blob'});
  }

  public theme1(){
    console.log( this.document.getElementById('theme'));
    this.document.getElementById('theme').setAttribute('href', '../assets/css/theme1.css');
  }

  public defaultTheme(){
    console.log( this.document.getElementById('theme'));
    this.document.getElementById('theme').setAttribute('href', '');
  }

  public updateLoginDetails(){
    this.isLogged = sessionStorage.getItem('isLogged') === 'true';
    this.loginResponse = JSON.parse(sessionStorage.getItem("loginResponse"));
  }

  printReport(reportname:string,id:any){
      this.downloadPDF(reportname,id).subscribe(
        (res) => {
          let fileURL = URL.createObjectURL(res);
          window.open(fileURL);
        }
      );
  }

  printEtiqueta(values: {}){
    this.downloadEtiqueta(values).subscribe(
      (res) => {
        let fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      }
    );
  }

  printExtratoFinanceiro(reportname:string,id:any){
    this.downloadPDF(reportname,id).subscribe(
      (res) => {
        let fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      }
    );
  }

  printBoleto(reportname:string,id:number){
    this.downloaBoleto(reportname,id).subscribe(
      (res) => {
        let fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      }
    );
  }

  downloadPDF(reportname:string,id:number): any {

    //if (report.needFilter){
     // report.filterData = this.builderFilterData(report.nome);
    //}

    return this.http.get(environment.getBaseURL()+'/report/' + reportname+"/"+ id,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  printRoteiro(ids:Array<string>) {
    this.downloadRoteiroFiscalizacaoPDF('RoteiroDeFiscalizacao', ids).subscribe(
      (res) => {
        let fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      }
    );
  }

  downloadRoteiroFiscalizacaoPDF(reportname:string, ids:Array<string>): any {
    return this.http.post(environment.getBaseURL()+'/report/fiscalizacao/' + reportname, ids,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  downloadDemonstrativoRetornoPagamento(name: string) {
    return this.http.get(`${environment.getBaseURL()}/report/${name}`,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  downloadEtiqueta(values: {}): any {
    console.log(JSON.stringify(values));
    return this.http.post(`${environment.getBaseURL()}/report/etiqueta-seguranca`, JSON.stringify(values),{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }), catchError(error => {
        console.log(error);
        const s = Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro na impressão dos selos',
          icon: 'error'
        })
        return error;
      }));
  }

  downloadArquivoRemessa(): any {

  }

  downloaBoleto(reportname:string,id:number): any {

    //if (report.needFilter){
    // report.filterData = this.builderFilterData(report.nome);
    //}

    return this.http.get(environment.getBaseURL()+'/boleto/' + reportname+"/"+ id,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  builderFilterData(nome: string): FilterData{

    let filter: FilterData;

    switch (nome) {

      default:
        filter = <FilterData>{
          limit: 1,
          whereClauses: <WhereClause[]>[{
            field: <FieldSearch>{label: "id", name: "id", type: "long"},
            dataType: DataType.LONG,
            matchType: MatchType.EQUALS,
            iniValue: SharedService.beanIdSelected
          }]
        };
        break;
    }

    return filter;
  }

  encodeImage(fileImage: File): void {
    let reader = new FileReader();
    reader.onload = () => {
      //console.log('Result ', reader.result);
    };

    reader.readAsDataURL(fileImage);
  }

  getOptionDefault(): DataTables.Settings {
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      ordering: true,
      order: [[0, 'desc']],
      language: {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    } as DataTables.Settings;
  }

  public downloadFromBlob(blob: Blob, name: string) {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('style', 'display: none;');
    a.href = url;
    a.download = name;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  baixarOficio(filename: string): any {
    return this.http.get(`${environment.getBaseURL()}/files/download/oficio/${filename}`, { responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }), catchError(error => {
        console.log(error);
        const s = Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro na impressão dos selos',
          icon: 'error'
        })
        return error;
      }));
  }

  downloadPDFFromFilter(reportname:string, filter: FilterDataHql): any {
    return this.http.post(environment.getBaseURL()+'/report/registro/' + reportname, filter,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  printFromFilter(reportname:string, filter: FilterDataHql) {
    this.spinnerService.show()
    this.downloadPDFFromFilter(reportname, filter)
      .subscribe((res) => {
        this.spinnerService.hide();
        let fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      }
    );
  }
}
