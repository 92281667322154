import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Profissional} from "./profissional";
import {Observable} from "rxjs";
import * as stringifyCustom from 'json-stringify-safe';
import {DomainBase} from "../../utils/utils";
import {catchError, first, map} from "rxjs/operators";
import {ProfissionalDocumentos} from "../profissional-documentos/profissional-documentos";
import {Resposta} from "../eleicao/resposta";


@Injectable({ providedIn: 'root' })
export class ProfissionalService extends CommonsService<Profissional> {

  getPathModule(): string {
    return 'profissional';
  }

  public getAnuidadeAnterior(year: number): Observable<ServerResponse<Profissional>> {
    return this.http.get<ServerResponse<Profissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/anuidade/${year}`);
  }

  public getProfissionaisParaLote(codes: Array<string>, year: string): Observable<ServerResponse<Profissional>> {
    return this.http.post<ServerResponse<Profissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/paralote`, JSON.parse(stringifyCustom({ code: codes, year: year })));
  }

  public getProfissionaisByProtocolo(object: {}): Observable<ServerResponse<Profissional>> {
    return this.http.post<ServerResponse<Profissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/findFilterProtocolo`, object);
  }

  public sendEmailNadaCostaEtico(bean: Profissional): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/sendmail/certidaonadaconstaetico`, bean);
  }

  public sendEmailNadaCosta(bean: Profissional): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/sendmail/certidaonadaconsta`, bean);
  }

  public getCounts(usuarioId: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/countconsolidacao/${usuarioId}`);
  }

  public getConsolidadosFromUsuario(usuarioId: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/consolidadoByUsuario/${usuarioId}`);
  }

  private checkDebitos(id: string): Observable<boolean> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/check-debitos/${id}`;
    return this.http.get<ServerResponse<any>>(url).pipe(
      map((response) => {
        if(response.success) {
          return response.content as boolean;
        } else {
          return false;
        }
      })
    );
  }

  private cobrancaAutomatica(id: string): Observable<boolean> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/cobranca-automatica/${id}`;
    return this.http.get<ServerResponse<any>>(url).pipe(
      map((response) => {
        if(response.success) {
          return response.content as boolean;
        } else {
          return false;
        }
      })
    );
  }

  public async verificarDebitos(id: string): Promise<boolean> {
    try { return await this.checkDebitos(id).toPromise(); } catch (e) { console.log(e); }
  }

  public async aticarCobrancaAutomatica(id: string): Promise<boolean> {
    try { return await this.cobrancaAutomatica(id).toPromise(); } catch (e) { console.log(e); }
  }

  public uploadFoto(id:string, formData:FormData): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/${id}/upload/foto-perfil`, formData);
  }

  public loadSimples(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/simples`);
  }

  public loadCampanha(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/campanha-atualizados`);
  }

  public loadAptosRegistro(): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/registro-coffito`;
    return this.http.get<ServerResponse<any>>(url).pipe(
      map((response) => {
        if(response.success) {
          return response.content as any;
        }
      })
    );
  }

  public registrarNoCoffito(ids: number[]): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/registro-coffito/registrar`;
    return  this.http.post<ServerResponse<any>>(url, ids).pipe(
      map((response) => {
        if(response.success) {
          return response.content as any;
        }
      })
    );
  }

  public registrarSerpro(id: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/registro-serpro/registrar`;
    return  this.http.post<ServerResponse<any>>(url, id).pipe(
      map((response) => {
        if(response.success) {
          return response.content as boolean;
        }
      })
    );
  }

  public removerSerpro(id: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/registro-serpro/cancelar`;
    return  this.http.post<ServerResponse<any>>(url, id).pipe(
      map((response) => {
        if(response.success) {
          return response.content as boolean;
        }
      })
    );
  }

  public registrarAssinatura(id: string, ass: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/${id}/registrar-assinatura`;
    return  this.http.post<ServerResponse<any>>(url, ass).pipe(
      map((response) => {
        if(response.success) {
          return response.content as boolean;
        }
      })
    );
  }

  public selectHistoricoEmpresa(profissionalId: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/${profissionalId}/historico-profissional`);
  }

  public selecionarPorAnuidade(anuidade: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/devedores-anuidade/${anuidade}`);
  }

  public addEleitor(profissionalId: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/eleicao/inclusao-fila`;
    return  this.http.post<ServerResponse<any>>(url, profissionalId).pipe(
      map((response) => {
        if(response) {
          return response.content as boolean;
        }
      })
    );
  }

  public resetarSenhaWeb(id: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/reset-senha`;
    return  this.http.post<ServerResponse<any>>(url, id).pipe(
      map((response) => {
        if(response) {
          return true;
        }
      })
    );
  }

  public enviarSMS(id: string, texto: string): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/${id}/enviar-sms`;
    return  this.http.post<ServerResponse<any>>(url, texto).pipe(
      map((response) => {
        if(response) {
          return true;
        }
      })
    );
  }

  public verificarCodicoesParaRegistroCoffito(id: string): Observable<boolean> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/${id}/codicoes-registro-coffito`;
    return  this.http.get<boolean>(url);
  }
}
