import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import { LayoutComponent } from './/layout.component';
import { AppHeader } from './/app-header/app-header.component';
import { AppSidebar } from './/app-sidebar/app-sidebar.component';
import { AppFooter } from './/app-footer/app-footer.component';
import {CommonModule} from "@angular/common";
import {InfracoesViewComponent} from "../entidades/termovisita/infracoes-view/infracoes-view.component";
import {DataTablesModule} from "angular-datatables";
import {NgxSmartModalModule} from "ngx-smart-modal";

@NgModule({
	declarations: [
	  LayoutComponent,
	  AppHeader,
	  AppSidebar,
	  AppFooter,
    InfracoesViewComponent
	],
	exports: [
	  LayoutComponent,
	  AppHeader,
	  AppSidebar,
	  AppFooter,
    InfracoesViewComponent
	],
    imports: [
        RouterModule,
        CommonModule,
        DataTablesModule,
        NgxSmartModalModule,
    ]
})
export class LayoutModule {
}
