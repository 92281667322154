import {FilterData, Filtro} from './components/interfaces';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {Injectable} from '@angular/core';
import {DomainBase} from './utils/utils';
import {map} from "rxjs/operators";
import * as stringifyCustom from 'json-stringify-safe';
import {Usuario} from "./entidades/usuario/usuario";
import {Ocorrencia} from "./entidades/ocorrencia/ocorrencia";
import {FilterDataHql} from "./shared/filterdata/filter-data-hql";
import {environment} from "../environments/environment";
import {Profissional} from "./entidades/profissional/profissional";
import {Guia} from "./entidades/guia/guia";

export class ServerResponse<T extends DomainBase> {
  content: any;
  filterData: FilterData;
  filtro: Filtro;
  success: boolean;
  message: string;
}

@Injectable()
export abstract class CommonsService<T extends DomainBase> {

  protected API_ROOT_URL = environment.getBaseURL();
  reportName = "";

  constructor(public http: HttpClient) {
  }

  abstract getPathModule(): string;

  public loadByFilterHql(filter: FilterDataHql): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/filter`, filter);
  }


  public loadByFilter(filterData: FilterData): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/`, filterData);
  }

  public sendAutorizacaoPrecariaTrabalho(bean: Profissional): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/profissional/sendmail/autorizacaoprecariatrabalho`, bean);
  }

  public busca(filtro: Filtro): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/`+ filtro.tipo +`/busca`, filtro);
  }
  public buscaparalote(filtro: Filtro): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/`+ filtro.tipo +`/buscaparalote`, filtro);
  }


  public loadAll(): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/loadall`);
  }


  public loadByFilter1(filterData: FilterData): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/profissional/`, filterData);
  }

  public storageUpdate(): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/storageupdate`);
  }


  public save(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/save`, JSON.parse(stringifyCustom(bean)));
  }
  public saveanuidade2020(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/saveanuidade2020`, JSON.parse(stringifyCustom(bean)));
  }

  public saveanuidade2020PJ(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/saveanuidade2020PJ`, JSON.parse(stringifyCustom(bean)));
  }


  public updateguia(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguia`, JSON.parse(stringifyCustom(bean)));
  }

  public updateguiaCT(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguiact`, JSON.parse(stringifyCustom(bean)));
  }

  public updateguiaedit(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguiaedit`, JSON.parse(stringifyCustom(bean)));
  }

  public updateguiaeditpj(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguiaeditpj`, JSON.parse(stringifyCustom(bean)));
  }

  public updateguiaeditct(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguiaeditct`, JSON.parse(stringifyCustom(bean)));
  }

  public updateguiapj(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/updateguiapj`, JSON.parse(stringifyCustom(bean)));
  }

  public saveanuidadeparcelada(bean: DomainBase, parcelas: string): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/saveanuidadeparcelada/${parcelas}`, JSON.parse(stringifyCustom(bean)));
  }

  public saveLog(user: Usuario): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/log/save`, JSON.parse(stringifyCustom(user)));
  }

  public saveOcorrencia(user: Usuario, ocorrenciaText: String, id: any): Observable<ServerResponse<T>> {
    const ocorrencia: Ocorrencia = {
      id: null,
      ocorrencia: ocorrenciaText,
      profissional_id: id,
      login: user.login,
      data: null,
      usuario_id: null,
      pessoaJuridica_id:null,
      consultorio_id: null

    };


    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/ocorrencia/save`, JSON.parse(stringifyCustom(ocorrencia)));
  }

  public savehistoricopj(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/historicoProfissional/savepj`, JSON.parse(stringifyCustom(bean)));
  }

  public gerainscdefunicapf(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/gerainscdefunicapf`, JSON.parse(stringifyCustom(bean)));
  }

  public liberausuarioweb(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/liberausuarioweb`, JSON.parse(stringifyCustom(bean)));
  }

  public gerainscdefparceladapf(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/gerainscdefparceladapf`, JSON.parse(stringifyCustom(bean)));
  }

  public agrupaguias(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/agrupaguias`, JSON.parse(stringifyCustom(bean)));
  }

  public negociar(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/negociacao/negociar`, JSON.parse(stringifyCustom(bean)));
  }

  public negociarPj(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/negociacao/negociarpj`, JSON.parse(stringifyCustom(bean)));
  }

  public savecobrancapj(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/savecobrancapj`, JSON.parse(stringifyCustom(bean)));
  }

  public savecobrancalote(bean: any): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/gerarlotecobranca`, bean );
  }

  public saveocorrencialote(bean: any): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/gerarloteocorrencia`, bean );
  }

  public savecobrancaconsultorio(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/savecobrancaconsultorio`, JSON.parse(stringifyCustom(bean)));
  }

  public gerenateBoleto(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/boleto/gerarboleto`, JSON.parse(stringifyCustom(bean)));
  }

  public sendByEmail(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/sendByEmail`, JSON.parse(stringifyCustom(bean)));
  }

  public sendEmailBoleto(bean: DomainBase): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/enviarbyemail`, JSON.parse(stringifyCustom(bean)));
  }

  public sendEmailBoletoBB(guia: Guia): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/boleto-por-email`, guia);
  }

  public getIndice(mesano): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/getindice`, mesano );
  }

  /*public importcsv(): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/ImportDataProf/importcsv`, "01");
  }*/

  public importcsv(content: string[]): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/importcsv`, content);
  }

  public getlastRod(): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/rod/getlastrod`);
  }

  public consultaNossoNumero(nossoNumero: string): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/consultanossonumero/`+nossoNumero);
  }

  public ajustanossonumeropj(nossoNumero: string): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/cobranca/ajustanossonumeropj/`+nossoNumero);
  }

    public update(bean: DomainBase): Observable<ServerResponse<T>> {
        return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/update`, JSON.parse(stringifyCustom(bean)));
    }

    public saveLote(bean: DomainBase): Observable<ServerResponse<T>> {
        console.log(bean);
        return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/saveLote`, JSON.parse(stringifyCustom(bean)));
    }

  public geraCDA(bean: DomainBase, id: string): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/save/${id}`, JSON.parse(stringifyCustom(bean)));
  }

  public loadQuery(filterData: FilterData, queryId: string): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/q/${queryId}`, filterData);
  }

  public findById(id: string): Observable<ServerResponse<T>> {
    return this.http.get<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/${id}`);
  }

  public findByCpad(cpad: number): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/findByCpad`, cpad );
  }

  public findByentiadeId(id: any): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/findByEntidadeId`, id );
  }

  public findTermoByentiadeId(id: any): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/termovisita/findByEntidadeId`, id );
  }

  public findPjById(id): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/drf/findpjbyid`, id );
  }

  public findFotoById(id): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/profissional/findfoto`, id );
  }

  public findNegociacaoById(id: number): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/negociacao/findById`, id );
  }

  public findNegociacaoGuiasOri(id: number): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/negociacao/findguiasori`, id );
  }

  public findNegociacaoGuiasAPagar(id: number): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/negociacao/findguiasapagar`, id );
  }

  public findProfiById(id: number): Observable<ServerResponse<T>> {
    return this.http.post<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/findProfById`, id );
  }

  public delete(id: string): any {
    return this.http.delete<ServerResponse<T>>(`${this.API_ROOT_URL}/${this.getPathModule()}/${id}`);
  }

  public deleteBtId(id: string): any {
    return this.http.delete<any>(`${this.API_ROOT_URL}/${this.getPathModule()}/${id}`);
  }

  public hashCode(value): any {
    var h = 0, l = value.length, i = 0;
    if (l > 0)
      while (i < l)
        h = (h << 5) - h + value.charCodeAt(i++) | 0;
    return h;
  };

  printReport(reportName: string){
    this.reportName = reportName;

    if(this.reportName !== null && this.reportName !== ""){
      this.downloadPDF().subscribe(
        (res) => {
          var fileURL = URL.createObjectURL(res);
          window.open(fileURL);
        }
      );
    }
  }


  downloadPDF(): any {
    let filter: FilterData = <FilterData>{
      page: 1,
      limit: 15,
      whereClauses: []
    };

    return this.http.post(this.API_ROOT_URL+'/reports/' + this.reportName, filter,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  emitirRelatorio(name: string, filter: FilterDataHql): Observable<any> {
    return this.http.post(this.API_ROOT_URL+'/report/registro/' + name, filter,{ responseType: 'blob'})
      .pipe(map(res => {
        return new Blob([res], { type: 'application/pdf', });
      }));
  }

  public getCountTotal(): Observable<number> {
    return this.http.get<number>(`${this.API_ROOT_URL}/${this.getPathModule()}/count`);
  }

  public getCountTotalFromMonths(): Observable<Array<number>> {
    return this.http.get<Array<number>>(`${this.API_ROOT_URL}/${this.getPathModule()}/count/inscritos`);
  }

  public getCountValues(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/count/helper`);
  }
}
