import Chart from 'chart.js';

export interface ChartValues {
  data: string;
  label: string;
  value: number;
}

export class ChartUtils {

  public static loadEasyPie() {
    $(function () {
      $('.easypie').each(function(){
        $(this).easyPieChart({
          trackColor: $(this).attr('data-trackColor') || '#f2f2f2',
          scaleColor: false,
        });
      });
    });
  }

  public static loadChart(name: string, type: string, data?: {}, dataOptions?: {}): Chart {
    let context = (<Chart>document.getElementById(name)).getContext('2d');

    return new Chart(context, {
      type: type,
      data: !data ? ChartUtils.getChartValueDefault() : data,
      options: !dataOptions ? ChartUtils.getChartOprionDefault() : dataOptions}
    );
  };

  public static getChartOprionDefault(): {} {
    return {
      responsive: true,
      maintainAspectRatio: false,
      showScale: true,
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
          },
        }],
        yAxes: [{
          gridLines: {
            display: true,
            drawBorder: true,
          },
        }]
      },
      legend: {
        labels: {
          boxWidth: 12
        }
      },
    };
  }

  public static getChartValueDefault(): {} {
    return {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          label: "Sessions",
          data: [20, 18, 40, 50, 35, 24, 40],
          borderColor: 'rgba(117,54,230,0.9)',
          backgroundColor: 'rgba(117,54,230,0.9)',
          pointBackgroundColor: 'rgba(117,54,230,0.9)',
          pointBorderColor: 'rgba(117,54,230,0.9)',
          borderWidth: 1,
          pointBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 30,
        },{
          label: "Data 2",
          data: [28, 48, 40, 35, 70, 33, 32],
          backgroundColor: 'rgba(255,64,129, 0.7)',
          borderColor: 'rgba(255,64,129, 0.7)',
          pointBackgroundColor: 'rgba(255,64,129, 0.7)',
          pointBorderColor: 'rgba(255,64,129, 0.7)',
          borderWidth: 1,
          pointBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 30,
        },{
          label: "Page Views",
          data: [64, 54, 60, 65, 52, 85, 48],
          borderColor: 'rgba(104,218,221,1)',
          backgroundColor: 'rgba(104,218,221,1)',
          pointBackgroundColor: 'rgba(104,218,221,1)',
          pointBorderColor: 'rgba(104,218,221,1)',
          borderWidth: 1,
          pointBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 30,
        },
      ],
    }
  }

  public static getDefaultOptionPie(): {} {
    return {
      legend: {
        labels: {
          boxWidth: 12,
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 50,
      circumference: (2 * Math.PI),
      animation: {
        animateRotate: true,
        animateScale: true
      }
    }
  }

}
