import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Notificacao} from "./notificacao";

@Injectable({ providedIn: 'root' }) 
export class NotificacaoService extends CommonsService<Notificacao> {

     getPathModule(): string { 
         return 'notificacao';
     } 
} 
