import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { AutoInfracao } from './autoinfracao'

@Injectable({ providedIn: 'root' }) 
export class AutoInfracaoService extends CommonsService<AutoInfracao> {

     getPathModule(): string { 
         return 'autoInfracao';
     } 
} 
