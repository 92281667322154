import {Component, AfterViewInit, OnInit} from '@angular/core';
import {ProtocoloService} from "../../entidades/protocolo/protocolo.service";
import {FilterDataHql} from "../../shared/filterdata/filter-data-hql";
import {WhereClauseHql} from "../../shared/filterdata/where-clause-hql";
import {ColumnMatch} from "../../shared/filterdata/column-match.enum";
import {ColumnType} from "../../shared/filterdata/column-type.enum";
import * as moment from 'moment';
import {Protocolo} from "../../entidades/protocolo/protocolo";

@Component({
  selector: '[app-header]',
  templateUrl: './app-header.component.html',
})
export class AppHeader implements OnInit,  AfterViewInit {

  protocolos: Array<Protocolo> = new Array<Protocolo>();

  constructor(private protocoloService: ProtocoloService) { }

  usuario: any ;
  activeUser: any;
  activeUserNome: any;
  activeUserId: number;
  activeUserRole: string;

  ngOnInit() {
    setTimeout(() => {
      this.usuario = JSON.parse(sessionStorage.getItem( "loginResponse"));
      this.activeUser = this.usuario.usuario;
      this.activeUserNome = this.activeUser.nome;
      this.activeUserId = this.activeUser.id;
      this.activeUserRole = this.activeUser.setor;
      //console.log(this.usuario);
      //console.log(this.activeUser.id);
      this.loadProtocolos();
    }, 1500);
  }

  ngAfterViewInit()  {
	}

	loadProtocolos() {
    const today = moment(new Date()).format('YYYY-MM-DD');

    const filter: FilterDataHql = {whereClauses: new Array<WhereClauseHql>()} as FilterDataHql;
    filter.whereClauses.push({columnName: 'setor.descricao', iniValue: this.activeUser.setor, columnMatch: ColumnMatch.EQUALS, columnType: ColumnType.STRING} as WhereClauseHql);
    filter.whereClauses.push({columnName: 'emissao', iniValue: today.concat(' 00:00:00'), endValue: today.concat(' 23:59:59'), columnType: ColumnType.DATE, columnMatch: ColumnMatch.BETWEEN} as WhereClauseHql);

    this.protocoloService.loadByFilterHql(filter).subscribe(response => {
      if(response.success) {
        this.protocolos = response.content;
        this.protocolos.sort((p1, p2) => p1.emissao < p2.emissao ? 1 : -1);
      }

    }, error => {
      console.log('Ocorreu um erro: ', error);
    })
  }

  getTempoCorrido(d: Date): string {
    if(d) {
      const diferenca = moment.duration(moment(new Date()).diff(moment(d)));

      //let seconds = diferenca;
      //let minutes = diferenca / 60;
      //let hours = diferenca / 3600;
      //let days = diferenca / 86400;
      //let weeks = diferenca / 604800;
      //let months = diferenca / 2419200;
      //let years = diferenca / 29030400;

      let seconds = diferenca.asSeconds();
      let minutes = diferenca.asMinutes();
      let hours = diferenca.asHours();
      let days = diferenca.asDays();
      let weeks = diferenca.asWeeks();
      let months = diferenca.asMonths();
      let years = diferenca.asYears();

      if (seconds <= 60) return "1 min atrás";
      else if (minutes <= 60) return minutes == 1 ? '1 min atrás' : Math.round(minutes) + ' min atrás';
      else if (hours <= 24) return hours == 1 ? '1 hrs atrás' : Math.round(hours) + ' hrs atrás';
      else if (days <= 7) return days == 1 ? '1 dia atras' : Math.round(days) + ' dias atrás';
      else if (weeks <= 4) return weeks == 1 ? '1 semana atrás' : Math.round(weeks) + ' semanas atrás';
      else if (months <= 12) return months == 1 ? '1 mês atrás' : Math.round(months) + ' meses atrás';
      else return years == 1 ? 'um ano atrás': Math.round(years) + ' anos atrás';
    }

    return "0";
  }
}
