import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { Livrofisioterapia} from './livrofisioterapia'

@Injectable({ providedIn: 'root' }) 
export class LivrofisioterapiaService extends CommonsService<Livrofisioterapia> {

     getPathModule(): string { 
         return 'livroFisioterapia';
     } 
} 
