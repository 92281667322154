import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {Profissional} from "../../profissional/profissional";
import {Consultorio} from "../../consultorio/consultorio";
import {Usuario} from "../../usuario/usuario";
import {AutoInfracaoService} from "../../autoinfracao/autoinfracao.service";
import {AutoInfracao} from "../../autoinfracao/autoinfracao";
import {AutoRdc} from "../../auto-rdc/auto-rdc";
import {AutoPecunia} from "../../auto-pecunia/auto-pecunia";
import {Notificacao} from "../../notificacao/notificacao";
import {AutoRdcService} from "../../auto-rdc/auto-rdc.service";
import {AutoPecuniaService} from "../../auto-pecunia/auto-pecunia.service";
import {NotificacaoService} from "../../notificacao/notificacao.service";
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import * as moment from "moment";
import {NgxSmartModalService} from "ngx-smart-modal";
import {SharedService} from "../../../shared/shared.service";

@Component({
  selector: '[app-infracoes-view]',
  templateUrl: './infracoes-view.component.html',
})
export class InfracoesViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() beanId: string;
  @Input() typeEntity: string;
  @Input() logado: Usuario;
  @Input() currentTarget: Profissional|Consultorio;

  @ViewChildren(DataTableDirective) dtElements: Array<DataTableDirective>;
  dtOptions: Array<DataTables.Settings> = new Array<DataTables.Settings>();
  dtTrigger: Array<Subject<any>>;

  infracoes: Array<AutoInfracao> = new Array<AutoInfracao>();
  rdcs: Array<AutoRdc> = new Array<AutoRdc>();
  pecunias: Array<AutoPecunia> = new Array<AutoPecunia>();
  notificacoes: Array<Notificacao> = new Array<Notificacao>();

  infracaoAtiva: AutoInfracao;
  rdcAtiva: AutoRdc;
  pecuniaAtiva: AutoPecunia;
  notificaAtiva: Notificacao;

  constructor(private infracaoService: AutoInfracaoService,
              private rdcService: AutoRdcService,
              private pecuniaService: AutoPecuniaService,
              public ngxModal: NgxSmartModalService,
              public sharedService: SharedService,
              private notificacaoService: NotificacaoService) {
    this.dtTrigger = new Array<Subject<{}>>();
    this.dtTrigger.push(new Subject<AutoInfracao>());
    this.dtTrigger.push(new Subject<AutoPecunia>());
    this.dtTrigger.push(new Subject<AutoRdc>());
    this.dtTrigger.push(new Subject<Notificacao>());
  }

  ngOnInit() {
    this.loadTableConfigs();
  }

  ngAfterViewInit() {
    this.dtTrigger.forEach(i => i.next());
  }

  ngOnDestroy() {
    this.dtTrigger.forEach(i => i.unsubscribe());
  }

  async loadContents() {
      await this.loadInfracoes();
      await this.loadPecunias();
      await this.loadRdcs();
      await this.loadNotificacoes();
      this.renderTable();
  }

  async loadInfracoes() {
    const response = await this.infracaoService.findByentiadeId(this.beanId).toPromise();
    if(response) {
      this.infracoes = response as unknown as Array<AutoInfracao>;
      // console.log('Infrações: ', this.infracoes);
      this.dtElements['_results'][0].dtOptions.data = this.infracoes;
    }
  }

  async loadRdcs() {
    const response = await this.rdcService.findByentiadeId(this.beanId).toPromise();
    if(response) {
      this.rdcs = response as unknown as Array<AutoRdc>;
      // console.log('RDCs: ', this.rdcs);
      this.dtElements['_results'][2].dtOptions.data = this.rdcs;
    }
  }

  async loadPecunias() {
    const response = await this.pecuniaService.findByentiadeId(this.beanId).toPromise();
    if(response) {
      this.pecunias = response as unknown as Array<AutoPecunia>;
      // console.log('Pecúnias: ', this.pecunias);
      this.dtElements['_results'][1].dtOptions.data = this.pecunias;
    }
  }

  async loadNotificacoes() {
    const response = await this.notificacaoService.findByentiadeId(this.beanId).toPromise();
    if(response) {
      this.notificacoes = response as unknown as Array<Notificacao>;
      // console.log('Notificações: ', this.notificacoes);
      this.dtElements['_results'][3].dtOptions.data = this.notificacoes;
    }
  }

  loadTableConfigs() {
    this.dtOptions[0] = this.getOptionDefault();
    this.dtOptions[0].columns = [
      {title: 'Termo', data: 'termo', name: 'termovisita_id', defaultContent: '', orderData: 0},
      {title: 'Data', data: 'data', name: 'data', orderData: 1, render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Número', data: 'numero', name: 'numero', orderData: 2, defaultContent: ''},
      {title: 'Agente Fiscal', data: 'fiscal', name: 'fiscal', orderData: 3, defaultContent: ''},
      {title: 'Prazo', data: 'prazo', name: 'prazo', orderData: 4, defaultContent: '', render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Status', data: 'status', name: 'status', orderData: 5, defaultContent: ''},
    ];
    this.dtOptions[0].rowCallback = ((row, data, index) => {
      $('td', row).css('cursor', 'pointer');

      row.addEventListener('click', () => {
         this.infracaoAtiva = data as AutoInfracao;
         this.ngxModal.open('modal-infracao');
      })
    });

    this.dtOptions[1] = this.getOptionDefault();
    this.dtOptions[1].columns = [
      {title: 'Termo', data: 'termo', name: 'termovisita_id', defaultContent: '', orderData: 0},
      {title: 'Data', data: 'data', name: 'data', orderData: 1, render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Número', data: 'numero', name: 'numero', orderData: 2, defaultContent: ''},
      {title: 'Agente Fiscal', data: 'fiscal', name: 'fiscal', orderData: 3, defaultContent: ''},
      {title: 'Prazo', data: 'prazo', name: 'prazo', orderData: 4, defaultContent: '', render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Status', data: 'status', name: 'status', orderData: 5, defaultContent: ''},
    ];
    this.dtOptions[1].rowCallback = ((row, data, index) => {
      $('td', row).css('cursor', 'pointer');

      row.addEventListener('click', () => {
        this.pecuniaAtiva = data as AutoPecunia;
        this.ngxModal.open('modal-pecunia');
      })
    });

    this.dtOptions[2] = this.getOptionDefault();
    this.dtOptions[2].columns = [
      {title: 'Termo', data: 'termo', name: 'termovisita_id', defaultContent: '', orderData: 0},
      {title: 'Data', data: 'data', name: 'data', orderData: 1, render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Número', data: 'numero', name: 'numero', orderData: 2, defaultContent: ''},
      {title: 'Agente Fiscal', data: 'fiscal', name: 'fiscal', orderData: 3, defaultContent: ''},
      {title: 'Prazo', data: 'prazo', name: 'prazo', orderData: 4, defaultContent: '', render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Status', data: 'status', name: 'status', orderData: 5, defaultContent: ''},
    ];
    this.dtOptions[2].rowCallback = ((row, data, index) => {
      $('td', row).css('cursor', 'pointer');

      row.addEventListener('click', () => {
        this.rdcAtiva = data as AutoRdc;
        this.ngxModal.open('modal-rdc');
      })
    });

    this.dtOptions[3] = this.getOptionDefault();
    this.dtOptions[3].columns = [
      {title: 'Termo', data: 'termo', name: 'termovisita_id', defaultContent: '', orderData: 0},
      {title: 'Data', data: 'data', name: 'data', orderData: 1, render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Número', data: 'numero', name: 'numero', orderData: 2, defaultContent: ''},
      {title: 'Agente Fiscal', data: 'fiscal', name: 'fiscal', orderData: 3, defaultContent: ''},
      {title: 'Prazo', data: 'prazo', name: 'prazo', orderData: 4, defaultContent: '', render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')},
      {title: 'Status', data: 'status', name: 'status', orderData: 5, defaultContent: ''},
    ];
    this.dtOptions[3].rowCallback = ((row, data, index) => {
      $('td', row).css('cursor', 'pointer');

      row.addEventListener('click', () => {
        this.notificaAtiva = data as Notificacao;
        this.ngxModal.open('modal-notificacao');
      })
    });
  }

  getOptionDefault(): DataTables.Settings {
    return {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: true,
      ordering: true,
      order: [[0, 'desc']],
      language: {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    } as DataTables.Settings;
  }

  renderTable(): void {
    this.dtElements.forEach((el, i) => {
      el.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger[i].next();
      });
    });
  }

  onAnyClose() {
    this.infracaoAtiva = undefined;
    this.rdcAtiva = undefined;
    this.pecuniaAtiva = undefined;
    this.notificaAtiva = undefined;
  }

  printReport(name: string, id: string) {
    this.sharedService.printReport(name, id);
  }
}
