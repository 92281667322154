import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Guia} from '../guia/guia';
import {Observable} from "rxjs";
import {Cobranca} from "./cobranca";
import {Profissional} from "../profissional/profissional";
import * as stringifyCustom from 'json-stringify-safe';
import {PessoaJuridica} from "../pessoajuridica/pessoajuridica";
import {DomainBase} from "../../utils/utils";
import {RetornoBoletoBB} from "../retorno-boleto-bb/retorno-boleto-bb";
import {HttpHeaders} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class CobrancaService extends CommonsService<Guia> {

  getPathModule(): string {
    return 'cobranca';
  }

  public registrarEnvioGrafica(): Observable<ServerResponse<Cobranca>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/cobranca/registrar-envio-grafica`);
  }

  public baixaboletos(): Observable<ServerResponse<Cobranca>> {
    return this.http.get<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/cobranca/baixaboletos`);
  }

  public gerarLoteAnuidade(profissionais: Array<Profissional>, pessoasJuridicas: Array<PessoaJuridica>, year: string, value: number): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/gerarloteanuidade`, profissionais);
  }

  public importArquivoRetorno(content: Array<string>): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/arquivoretorno`, content);
  }

  public getArquivoRemessa(anuidade: string, tipoId: string, isTeste: boolean): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/arquivoremessa`, JSON.parse(stringifyCustom({
      anuidade: anuidade,
      tipoCobrancaId: tipoId,
      isTeste: isTeste
    })));
  }

  public saveParcelado(bean: DomainBase, parcelas: String): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/save/parcelado/${parcelas}`, JSON.parse(stringifyCustom(bean)));
  }

  public removerGuias(ids: Array<string>): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/remover-guias`, JSON.parse(stringifyCustom(ids)));
  }

  public registrarBolotoBB(tipo: string, guia: Guia): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/cobranca/registrar-boleto/bb/${tipo}`, guia);
  }

  public registarCobranca(bean: DomainBase): Observable<ServerResponse<Cobranca>> {
    return this.http.post<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/registrar`, bean);
  }

  public atualizarCobranca(bean: DomainBase): Observable<ServerResponse<Cobranca>> {
    return this.http.put<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/atualizar`, bean);
  }

  public selecionarDebitos(profissionalId: string): Observable<ServerResponse<Cobranca>> {
    return this.http.get<ServerResponse<Cobranca>>(`${this.API_ROOT_URL}/${this.getPathModule()}/referencia/${profissionalId}`);
  }

  carregarPagamentos(tipo: string, ini: string, fim: string): Observable<ServerResponse<RetornoBoletoBB>> {
    return this.http.get<ServerResponse<RetornoBoletoBB>>(`${this.API_ROOT_URL}/${this.getPathModule()}/pagamentos-bb/${tipo}/${ini}/${fim}`);
  }

  carregarControlePagamentos(tipo: string, ini: string, fim: string): Observable<ServerResponse<RetornoBoletoBB>> {
    return this.http.get<ServerResponse<RetornoBoletoBB>>(`${this.API_ROOT_URL}/${this.getPathModule()}/pagamentos/${tipo}/${ini}/${fim}`);
  }

  consultarPagamentos(data: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<RetornoBoletoBB>>(`${this.API_ROOT_URL}/${this.getPathModule()}/consultar-pagamentos-bb/${data}`);
  }

  gerarAnuidade2023(tipo: string, content: any): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/gerar-anuidade/${tipo}`;
    return this.http.post(url, content);
  }

  gerarAnuidadePrimeiraInscricao(content: any): Observable<any> {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/anuidade/primeira-inscricao`;
    return this.http.post(url, content);
  }

  consultarBoleto(numero: string): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<RetornoBoletoBB>>(`${this.API_ROOT_URL}/${this.getPathModule()}/consultar-boleto/${numero}`);
  }

  carregarPagamentosPIX(ini: string, fim: string): Observable<any> {
    return this.http.get<any>(`${this.API_ROOT_URL}/${this.getPathModule()}/consulta-pagamento-pix?dataIni=${ini}&dataFim=${fim}`);
  }

  enviarBoletoPorWhatApps(profissionalId: number, guiaId: number, conteudo: string): Observable<any> {
    return this.http.post<any>(`${this.API_ROOT_URL}/${this.getPathModule()}/guia/${guiaId}/${profissionalId}/enviar-boleto-zap`, conteudo);
  }

  enviarArquivoBBT(file: File): any {
    let formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.API_ROOT_URL}/${this.getPathModule()}/bb/arquivo-bbt`, formData);
  }
}
