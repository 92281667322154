import { Injectable } from '@angular/core';
import {CommonsService, ServerResponse} from "../../commons-service";
import {SolicitacaoCertidao} from "./solicitacao-certidao";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class SolicitacaoCertidaoService extends CommonsService<SolicitacaoCertidao> {

  getPathModule(): string {
    return "solicitacaocertidao";
  }

  public findByHashCode(hasCode: string): Observable<ServerResponse<SolicitacaoCertidao>> {
    return this.http.get<ServerResponse<SolicitacaoCertidao>>(`${this.API_ROOT_URL}/${this.getPathModule()}/validar/${hasCode}`);
  }
}
