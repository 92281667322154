import { RouterModule,Routes } from '@angular/router';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {OcorrenciaGridComponent} from './ocorrencia-grid/ocorrencia-grid.component';
import {OcorrenciaFormComponent} from './ocorrencia-form/ocorrencia-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import {CobrancaModule} from '../cobranca/cobranca.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import {OcorrenciaViewComponent} from "./ocorrencia-view/ocorrencia-view.component";
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material';
import {NgxSmartModalModule} from "ngx-smart-modal";


const routes: Routes = [];


@NgModule({
    imports: [RouterModule.forChild(routes),
        SharedModule,
        CobrancaModule,
        ReactiveFormsModule,
        DataTablesModule,
        MatPaginatorModule,
        MatTableModule,
        MatIconModule,
        NgbModule, NgxSmartModalModule
    ],
  declarations: [
    OcorrenciaGridComponent,OcorrenciaFormComponent, OcorrenciaViewComponent
  ],
  schemas: [],
  providers: [] ,
  exports:[
    OcorrenciaGridComponent
  ]
})
export class OcorrenciaModule {}


