import {CommonsService} from '../../commons-service';
import {Injectable} from "@angular/core";
import { Rod} from './rod'

@Injectable({ providedIn: 'root' }) 
export class RodService extends CommonsService<Rod> {

     getPathModule(): string { 
         return 'rod';
     } 
} 
