import {CommonsService} from '../../commons-service'; 
import {Injectable} from "@angular/core";
import {Indice} from './indice'; 

@Injectable({ providedIn: 'root' }) 
export class IndiceService extends CommonsService<Indice> { 

     getPathModule(): string { 
         return 'indice'; 
     } 
} 
