import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Usuario} from "../../usuario/usuario";
import {ProcessoAdministrativo} from "../processo-administrativo";
import {FaseProcessoAdministrativo} from "../../fase-processo-administrativo/fase-processo-administrativo";
import {ProcessoAdministrativoService} from "../processo-administrativo.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import * as moment from "moment";
import {Guia} from "../../guia/guia";
import {Profissional} from "../../profissional/profissional";
import {PessoaJuridica} from "../../pessoajuridica/pessoajuridica";
import {Consultorio} from "../../consultorio/consultorio";
import {ToastrService} from "ngx-toastr";
import {HistoricoProcessoAdministrtivo} from "../../historico-processo-administrativo/historico-processo-administrtivo";
import {Setor} from "../../setor/setor";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: '[processo-administrativo-form]',
  templateUrl: './processo-administrativo-form.component.html',
  styleUrls: ['./processo-administrativo-form.component.scss']
})
export class ProcessoAdministrativoFormComponent implements OnInit {

  processoADM: ProcessoAdministrativo = {} as ProcessoAdministrativo;
  historicoPA: HistoricoProcessoAdministrtivo = {} as HistoricoProcessoAdministrtivo;
  atualFase: FaseProcessoAdministrativo = {} as FaseProcessoAdministrativo;
  despachoFase: FaseProcessoAdministrativo = {} as FaseProcessoAdministrativo;
  guiasOriginais: Array<Guia> = new Array<Guia>();
  gerarDespacho: boolean = true;

  @Input() logado: Usuario;
  @Input() setores: Array<Setor> = new Array<Setor>();
  @Input() currentTarget: Profissional|PessoaJuridica|Consultorio|null;

  @Output() emitterRegistro: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public processoAdmService: ProcessoAdministrativoService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              public ngxModal: NgxSmartModalService) { }

  ngOnInit() {
  }

  //TODO - Atenção ao adicionar nova fase, favor incluir no array e tratar no método onChangeValues do tipo [fase]
  public getFases(): Array<{label: string, value: string}> {
    return this.processoAdmService.getFases();
  }

  public onChangeValues(event, type: string) {
    switch (type) {
      case 'prazo': {
        const prazo = this.atualFase.prazo;
        if(prazo) {
          this.atualFase.validade = moment().add(prazo, 'days').toDate();
        }
        break;
      }

      case 'fase': {
        switch (this.atualFase.name) {
          case 'DESPACHO': {
            this.atualFase.fase = '1';
            break;
          }

          case 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA': {
            this.atualFase.fase = '2';
            break;
          }

          case 'AR POSTADO': {
            this.atualFase.fase = '3';
            break;
          }

          case 'AR DEVOLVIDO': {
            this.atualFase.fase = '3';
            break;
          }

          case 'AR ENTREGUE': {
            this.atualFase.fase = '3';
            break;
          }

          case 'COBRANÇA INDEVIDA': {
            this.atualFase.fase = '4';
            break;
          }

          case 'FINALIZADO POR PAGAMENTO': {
            this.atualFase.fase = '4';
            break;
          }

          case 'INSCRIÇÃO EM DÍVIDA ATIVA': {
            this.atualFase.fase = '5';
            break;
          }

          case 'EMISSÃO DA CDA': {
            this.atualFase.fase = '5';
            break;
          }

          case 'NOTIFICAÇÃO POR EDITAL': {
            this.atualFase.fase = '6';
            break;
          }

          default: {
            break;
          }
        }
      }
    }
  }

  public getTotalOrigem(): number {
    if(this.guiasOriginais && this.guiasOriginais.length) {
      let total = 0;
      this.guiasOriginais.forEach(g => total += g.valorTotal || 0);
      return total;
    }
    return 0;
  }

  public onClickAbrirProcesso() {
    if(this.currentTarget) {
      let p = this.currentTarget as Profissional;

      if(p.liberaportal) {
        if(this.guiasOriginais.length) {
          this.processoADM.dataAbertura = new Date();
          this.processoADM.faseAtual = 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA';
          this.processoADM.status = 'ABERTURA';
          this.processoADM.totalDebito = Number(this.getTotalOrigem().toFixed(2));

          this.despachoFase.fase = '1';
          this.despachoFase.name = 'DESPACHO';
          this.despachoFase.prazo = 0;
          this.despachoFase.situacao = "ABERTURA";
          this.despachoFase.validade = null;

          this.atualFase.fase = '2';
          this.atualFase.name = 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA';
          this.atualFase.prazo = 30;
          this.atualFase.situacao = "ABERTURA";
          this.atualFase.validade = moment().add(this.atualFase.prazo, 'days').toDate();

          this.ngxModal.open('modal-processo');
        } else {
          this.toastr.error('Para abrir um processo é necessário ter mais de uma guia!', 'Atenção', {closeButton: true, timeOut: 5000, progressBar: true});
        }

      } else {
        this.toastr.error('Não possível abrir processos para profissional não web.', 'Atenção', {closeButton: true, timeOut: 5000, progressBar: true});
      }
    }
  }

  public registrarProcesso() {
    this.spinner.show();
    this.historicoPA.situacao = 'ABERTO';
    this.historicoPA.data = new Date();
    this.historicoPA.setorOrigem = this.setores.find(s => s.descricao ==  this.processoADM.localAtual);
    this.historicoPA.setorAtual = this.setores.find(s => s.descricao ==  this.processoADM.localAtual);

    this.processoADM.profissional = this.currentTarget as Profissional;
    this.processoADM.dataAbertura = new Date();

    this.processoADM.historico = new Array<HistoricoProcessoAdministrtivo>();
    this.processoADM.historico.push(this.historicoPA);

    this.processoADM.guias = new Array<Guia>();
    this.guiasOriginais.forEach(i => this.processoADM.guias.push(i));

    this.processoADM.fases = new Array<FaseProcessoAdministrativo>();

    this.despachoFase.situacao = 'FINALIZADA';
    this.despachoFase.isComplete = true;
    this.despachoFase.data = new Date();
    this.despachoFase.emissor = this.logado.nome;
    this.processoADM.fases.push(this.despachoFase);

    this.atualFase.situacao = "EM ABERTO";
    this.atualFase.isComplete = false;
    this.atualFase.data = new Date();
    this.atualFase.emissor = this.logado.nome;
    this.processoADM.fases.push(this.atualFase);

    this.processoAdmService.save(this.processoADM).subscribe(response => {
      if(response) {
        this.toastr.success('Processo aberto com sucesso', 'Sucesso', {closeButton: true, timeOut: 5000, progressBar: true});
        this.ngxModal.closeAll();
        this.emitterRegistro.emit(true);
      }

      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.toastr.error('Ocorreu um erro na tentativa de abertura de processo.', 'Atenção', {closeButton: true, timeOut: 5000, progressBar: true});
      console.error(error.message);
    });
  }

  onAnyClose() {
    this.processoADM = {} as ProcessoAdministrativo;
    this.historicoPA = {} as HistoricoProcessoAdministrtivo;
    this.atualFase = {} as FaseProcessoAdministrativo;
    this.despachoFase = {} as FaseProcessoAdministrativo;
    this.guiasOriginais = new Array<Guia>();
  }
}
