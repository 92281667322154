import { RouterModule } from '@angular/router';
import {CurrencyPipe} from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import { DataTablesModule } from 'angular-datatables';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

//data
import { MAT_DATE_LOCALE} from '@angular/material/core';

// Material Imports
import {  MatNativeDateModule, MatInputModule } from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker'


import {  MatButtonModule,  MatIconModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

import {CobrancaComponent, DialogOverviewExampleDialog} from './cobranca.component';
import {NgxSmartModalModule} from "ngx-smart-modal";
import {ProcessoAdministrativoModule} from "../processo-administrativo/processo-administrativo.module";



const ROUTES  = [];

@NgModule({
    imports: [RouterModule.forChild(ROUTES),
        SharedModule,
        MatDialogModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        CurrencyMaskModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        NgbModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        DataTablesModule,
        NgxSmartModalModule.forRoot(), ProcessoAdministrativoModule
    ],
  exports: [CobrancaComponent] ,
  declarations: [
    CobrancaComponent,DialogOverviewExampleDialog
  ],
  entryComponents: [CobrancaComponent, DialogOverviewExampleDialog],
  schemas: [],
  providers: [CurrencyPipe, { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },{provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},]
})
export class CobrancaModule {}
