import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Consultorio} from "./consultorio";
import {Observable} from "rxjs";


@Injectable({providedIn: 'root'})
export class ConsultorioService extends CommonsService<Consultorio> {
  getPathModule(): string {
    return 'consultorio';
  }

  registrarConsultorio(id: string, content: any): Observable<Consultorio> {
    return this.http.post<Consultorio>(`${this.API_ROOT_URL}/${this.getPathModule()}/${id}/registro`, content);
  }

  resetarSenhaWeb(id: number) {
    const url = `${this.API_ROOT_URL}/${this.getPathModule()}/${id}/reset-senha`;
    return  this.http.post<ServerResponse<any>>(url, null);
  }
}
