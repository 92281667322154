import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {PessoaJuridica} from "../pessoajuridica/pessoajuridica";
import {Historicoprofissional} from "./historicoprofissional";


@Injectable({ providedIn: 'root' })
export class HistoricoProfissionalService extends CommonsService<Historicoprofissional> {

     getPathModule(): string {
         return 'historicoProfissional';
     }

  public loadByRod(value: string): Observable<ServerResponse<Historicoprofissional>> {
    return this.http.get<ServerResponse<Historicoprofissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/loadbyrod/${value}`);
  }
}
