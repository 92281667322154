import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {Negociacao} from './negociacao';
import {Observable} from "rxjs";
import * as stringifyCustom from 'json-stringify-safe';

@Injectable({ providedIn: 'root' })
export class NegociacaoService extends CommonsService<Negociacao> {

  getPathModule(): string {
    return 'negociacao';
  }

  public desfazerAcordo(list: Array<string>, id: string): Observable<ServerResponse<any>> {
    return this.http.post<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/desfazeracordo`,  JSON.parse(stringifyCustom({ list: list, id: id })));
  }
}
