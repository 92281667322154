import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {Ocorrencia} from "../ocorrencia";
import * as moment from "moment";
import {OcorrenciaService} from "../ocorrencia.service";
import {Profissional} from "../../profissional/profissional";
import {PessoaJuridica} from "../../pessoajuridica/pessoajuridica";
import {Consultorio} from "../../consultorio/consultorio";
import {FilterDataHql} from "../../../shared/filterdata/filter-data-hql";
import {WhereClauseHql} from "../../../shared/filterdata/where-clause-hql";
import {ColumnType} from "../../../shared/filterdata/column-type.enum";
import {ColumnMatch} from "../../../shared/filterdata/column-match.enum";
import {NgxSmartModalService} from "ngx-smart-modal";
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
import {Usuario} from "../../usuario/usuario";
import Swal from "sweetalert2";
import {Guia} from "../../guia/guia";
import Data = google.maps.Data;


@Component({
  selector: '[app-ocorrencia-grid]',
  templateUrl: './ocorrencia-grid.component.html',
  //styleUrls: ['./ocorrencia-grid.component.css']
})
export class OcorrenciaGridComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() logado: Usuario;
  @Input() beanId: string;
  @Input() typeEntity: string;
  @Input() currentTarget: Profissional | PessoaJuridica | Consultorio;

  @ViewChild(DataTableDirective) dtElements: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Ocorrencia>;

  ocorrenciaAtiva: Ocorrencia = {} as Ocorrencia;
  ocorrencias: Array<Ocorrencia> = new Array<Ocorrencia>();

  constructor(public ocorrenciaService: OcorrenciaService,
              public toastService: ToastrService,
              public spinnerService: NgxSpinnerService,
              public modalService: NgxSmartModalService) {
    this.dtTrigger = new Subject<Ocorrencia>();
  }

  ngOnInit() {
    this.loadTableConfigs();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  loadTableConfigs() {
    this.dtOptions = this.getOptionDefault();
    this.dtOptions.rowCallback = ((row, data, index) => {
      $('td', row).css('cursor', 'pointer');

      row.addEventListener('click', () => {
        this.ocorrenciaAtiva = data as Ocorrencia;
        this.modalService.open('modal-verocorrencia');
      })
    });
  }

  getOptionDefault(): DataTables.Settings {
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      autoWidth: true,
      ordering: true,
      order: [[0, 'desc']],
      columns: [
        {title: 'Código', data: 'id', name: 'id', orderData: 0},
        {
          title: 'Data',
          data: 'data',
          name: 'data',
          orderData: 1,
          render: (data, type, row, meta) => data = moment(data).format('DD/MM/YYYY')
        },
        {title: 'Usuário', data: 'login', name: 'login', orderData: 2, defaultContent: ''},
        {title: 'Ocorrência', data: 'ocorrencia', name: 'ocorrencia', orderData: 3, defaultContent: ''},
      ],
      language: {
        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        thousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      }
    } as DataTables.Settings;
  }

  renderTable(): void {
    this.dtElements.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadOcorrencias(): void {
    if (this.beanId && this.currentTarget) {
      const filter = {whereClauses: new Array<WhereClauseHql>()} as FilterDataHql;

      switch (this.typeEntity) {
        case 'PF': {
          filter.whereClauses.push({
            columnName: 'profissional_id',
            iniValue: this.beanId,
            columnType: ColumnType.LONG,
            columnMatch: ColumnMatch.EQUALS
          } as WhereClauseHql);
          break;
        }
        case 'PJ': {
          filter.whereClauses.push({
            columnName: 'pessoaJuridica_id',
            iniValue: this.beanId,
            columnType: ColumnType.LONG,
            columnMatch: ColumnMatch.EQUALS
          } as WhereClauseHql);
          break;
        }
        case 'CT': {
          filter.whereClauses.push({
            columnName: 'consultorio_id',
            iniValue: this.beanId,
            columnType: ColumnType.LONG,
            columnMatch: ColumnMatch.EQUALS
          } as WhereClauseHql);
          break;
        }
      }

      this.ocorrenciaService.loadByFilterHql(filter)
        .subscribe(response => {
          if (response) {
            this.ocorrencias = response as unknown as Array<Ocorrencia>;
            this.dtElements.dtOptions.data = this.ocorrencias;
            this.renderTable();
          }
        }, error => {
          console.log('Ocorreu um erro ao carregar as ocorrências. ', error);
        });
    }
  }

  onClickAction(type: string) {
    if (this.ocorrenciaAtiva) {
      switch (type) {
        case 'add': {
          this.spinnerService.show();
          // console.log('Nova ocorrência: ', this.ocorrenciaAtiva);
          this.ocorrenciaService.save(this.ocorrenciaAtiva)
            .subscribe(response => {
              this.modalService.close('modal-novaocorrencia');
              this.toastService.success('Uma nova ocorrência foi criada.', 'Tudo Certo!');
              this.spinnerService.hide();
              this.dtElements.dtOptions.data.push(response as unknown as Ocorrencia);
              this.renderTable();
            }, error => {
              this.spinnerService.hide();
              this.toastService.error('Ocorreu um erro na tentativa de criar uma ocorrência.', 'Atenção!');
              console.log('Ocorreu um erro na tentativa de criar uma ocorrência. ', error);
            });
          break;
        }
        case 'remove': {
          let ocorrenciaToRemove = this.ocorrenciaAtiva as Ocorrencia;
          this.modalService.close('modal-verocorrencia');
          Swal.fire({
            icon: 'question',
            title: 'Remoção de Ocorrência',
            text: `Essa ação resultará na remoção da ocorrência. Tem certeza que deseja continuar?`,
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
              this.remove(ocorrenciaToRemove);
            }
          });
          break;
        }
      }
    }
  }

  remove(ocorrencia: Ocorrencia) {
    this.spinnerService.show();
    this.ocorrenciaService.delete(ocorrencia.id)
      .subscribe(response => {
        this.modalService.close('modal-verocorrencia');
        this.toastService.success('A ocorrência foi removida.', 'Tudo Certo!');
        this.spinnerService.hide();

        const i = this.dtElements.dtOptions.data.findIndex(o => o.id == this.ocorrenciaAtiva.id);
        this.dtElements.dtOptions.data.splice(i, 1);
        this.renderTable();
      }, error => {
        this.spinnerService.hide();
        this.toastService.error('Ocorreu um erro na tentativa de remover a ocorrência selecionada.', 'Atenção!');
        console.log('Ocorreu um erro na tentativa de remover a ocorrência selecionada. ', error);
      });
  }

  onOperModal(type: string) {
    switch (type) {
      case 'new': {
        this.ocorrenciaAtiva = {} as Ocorrencia;
        this.ocorrenciaAtiva.data = moment().toDate();
        this.ocorrenciaAtiva.usuario_id = this.logado.id.toString();
        this.ocorrenciaAtiva.login = this.logado.nome;
        if ("PF" == this.typeEntity) {
          this.ocorrenciaAtiva.profissional_id = this.currentTarget.id.toString();
        } else if ("PJ" == this.typeEntity) {
          this.ocorrenciaAtiva.pessoaJuridica_id = this.currentTarget.id.toString();
        } else if ("CT" == this.typeEntity) {
          this.ocorrenciaAtiva.consultorio_id = this.currentTarget.id.toString();
        }
      }
    }
  }

  onAnyClose() {
    this.ocorrenciaAtiva = {} as Ocorrencia;
  }
}
