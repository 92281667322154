import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProcessoAdministrativo} from "../processo-administrativo";
import {Setor} from "../../setor/setor";
import {FaseProcessoAdministrativo} from "../../fase-processo-administrativo/fase-processo-administrativo";
import {ProcessoAdministrativoService} from "../processo-administrativo.service";
import {NgxSmartModalService} from "ngx-smart-modal";
import {WhereClauseHql} from "../../../shared/filterdata/where-clause-hql";
import {FilterDataHql} from "../../../shared/filterdata/filter-data-hql";
import {PessoaJuridica} from "../../pessoajuridica/pessoajuridica";
import {ColumnType} from "../../../shared/filterdata/column-type.enum";
import {ColumnMatch} from "../../../shared/filterdata/column-match.enum";
import {Profissional} from "../../profissional/profissional";
import {Consultorio} from "../../consultorio/consultorio";
import {SharedService} from "../../../shared/shared.service";
import {FormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {Usuario} from "../../usuario/usuario";
import {Guia} from "../../guia/guia";

@Component({
  selector: '[processo-administrativo-view]',
  templateUrl: './processo-administrativo-view.component.html',
  styleUrls: ['./processo-administrativo-view.component.scss']
})
export class ProcessoAdministrativoViewComponent implements OnInit {


  listProcessos: Array<ProcessoAdministrativo> = [];
  gerarDespacho: boolean = true;
  incluirObs: boolean = true;

  guiasOriginais: Array<Guia> = new Array<Guia>();

  @Input() logado: Usuario;
  @Input() beanType: string;
  @Input() currentTarget: Profissional|PessoaJuridica|Consultorio|null;
  @Input() setores: Array<Setor> = new Array<Setor>();

  @Output() clickNovaFase: EventEmitter<ProcessoAdministrativo> = new EventEmitter<ProcessoAdministrativo>();
  @Output() clickVerFase: EventEmitter<{pa: ProcessoAdministrativo, fase: FaseProcessoAdministrativo}> = new EventEmitter<{pa: ProcessoAdministrativo, fase: FaseProcessoAdministrativo}>();

  constructor(public processoAdmService: ProcessoAdministrativoService,
              private sharedService: SharedService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              public ngxModal: NgxSmartModalService,) { }

  ngOnInit() {
  }

  public loadProcessosAdm() {
    if(this.currentTarget != null) {
      const filter = {whereClauses: new Array<WhereClauseHql>()} as FilterDataHql;

      if (this.beanType == "PessoaJuridicaFormComponent" ) {
        const pj = this.currentTarget as PessoaJuridica;
        filter.whereClauses.push({columnName: 'profissional.id', iniValue: pj.id.toString(), columnType: ColumnType.LONG, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);

      } else if (this.beanType == "ProfissionalFormComponent" ) {
        const pf = this.currentTarget as Profissional;
        filter.whereClauses.push({columnName: 'profissional.id', iniValue: pf.id.toString(), columnType: ColumnType.LONG, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);

      } else if (this.beanType == "ConsultorioFormComponent" ) {
        const ct = this.currentTarget as Consultorio;
        filter.whereClauses.push({columnName: 'profissional.id', iniValue: ct.id.toString(), columnType: ColumnType.LONG, columnMatch: ColumnMatch.EQUALS} as WhereClauseHql);
      }

      this.processoAdmService.loadByFilterHql(filter)
        .subscribe(response => {
          if(response) {
            this.listProcessos = response as unknown as Array<ProcessoAdministrativo>;
            this.listProcessos.sort((p1, p2) => p1.id < p2.id ? 1 : -1);
            this.listProcessos.forEach(processo => processo.fases.sort((f1, f2) => f1.id > f2.id ? 1 : -1));
            // console.log('Processos de Cobranças: ', this.listProcessos);
          }
        }, error => {
          console.error(error.message);
        });
    }
  }

  public onClickPrint(type: string, p: ProcessoAdministrativo) {
    switch (type) {
      case 'CARTA': {
        const g = p.guias.find(g => g.tipocobranca_id.id.toString() == '56');
        if(g && g.guia && g.guia.length) {
          this.sharedService.printReport('MinutaNotificacaoPF', p.id);
        } else {
          this.toastr.error('Título não Registrado', 'Atenção');
        }
        break;
      }

      case 'DESPACHO': {
        this.sharedService.printReport('DespachoProcessoAdministartivo', p.id);
        break;
      }

      case 'AR': {
        this.sharedService.printReport('AvisoRecebimento', p.id)
        break;
      }
    }
  }

  public onClickSolicitarDocumentos(processo: ProcessoAdministrativo) {
    alert('Em Desenvolvimento');
  }

  //TODO - Atenção ao adicionar nova fase, favor incluir no array e tratar no método onChangeValues do tipo [fase]
  public getFases(): Array<{label: string, value: string}> {
    return this.processoAdmService.getFases();
  }

  public getGuiasPA(processo: ProcessoAdministrativo): Array<Guia> {
    return processo.guias.filter(g => g.situacao == 'PA').sort((g1, g2) => g1.datavencimento > g2.datavencimento ? 1 : -1);
  }

  public getGuia56(processo: ProcessoAdministrativo): Guia {
    return processo.guias.find(g => g.tipocobranca_id.id.toString() == '56');
  }

  public onClickNovaFase(p: ProcessoAdministrativo) {
    this.clickNovaFase.emit(p);
  }

  public onClickFaseProcesso(p: ProcessoAdministrativo, f: FaseProcessoAdministrativo) {
    this.clickVerFase.emit({pa: p, fase: f});
  }
}
