import { Injectable } from '@angular/core';
import {CommonsService, ServerResponse} from "../../commons-service";
import {Protocolo} from "./protocolo";
import {HttpClient} from "@angular/common/http";
import {SharedService} from "../../shared/shared.service";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class ProtocoloService extends CommonsService<Protocolo> {

  constructor(public http: HttpClient, public sharedService: SharedService) { super(http) }

  getPathModule(): string {
    return 'protocolo';
  }

  public getBySetor(setorId: string): Observable<ServerResponse<Protocolo>> {
    const origem = this.sharedService.loginResponse.usuario.setor;
    return this.http.get<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/setor/${setorId}/${origem}`);
  }

  public receberProtocolo(bean: Protocolo, notificar?: boolean, anotacao?: string): Observable<ServerResponse<Protocolo>> {
    return this.http.post<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/receber${notificar ? '?notificar=true&anotacao=' + anotacao : '?notificar=false'}`, bean);
  }

  public encaminharProtocolo(bean: any, notificar?: boolean, anotacao?: string): Observable<ServerResponse<Protocolo>> {
    return this.http.post<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/encaminhar/${notificar ? '?notificar=true&anotacao=' + anotacao : '?notificar=false'}`, bean);
  }

  public finalizarProtocolo(bean: Protocolo): Observable<ServerResponse<Protocolo>> {
    return this.http.post<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/finalizar/`, bean);
  }

  public finalizarProtocolo2(bean: Protocolo, caminhos: Array<string>): Observable<ServerResponse<Protocolo>> {
    return this.http.post<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/finalizar2`, {protocolo: bean, caminhos: caminhos});
  }

  public cancelarProtocolo(bean: Protocolo): Observable<ServerResponse<Protocolo>> {
    return this.http.post<ServerResponse<Protocolo>>(`${this.API_ROOT_URL}/${this.getPathModule()}/cancelar/`, bean);
  }

  public uploadArquivoTemporario(formdata: FormData): Observable<any> {
    return this.http.post(`${this.API_ROOT_URL}/profissionaldocumentos/upload/temporario`, formdata);
  }

  public selecionarPorCodigo(codigo: string): Observable<any> {
    return this.http.get(`${this.API_ROOT_URL}/${this.getPathModule()}/numero/${codigo}`);
  }

  public selecionarDirecionados(usuarioId: string): Observable<any> {
    return this.http.get(`${this.API_ROOT_URL}/${this.getPathModule()}/usuario/${usuarioId}/direcionados`);
  }
}
