import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import {PessoaJuridica} from "./pessoajuridica";
import {Observable} from "rxjs";
import * as stringifyCustom from 'json-stringify-safe';
import {Profissional} from "../profissional/profissional";


@Injectable({ providedIn: 'root' })
export class PessoaJuridicaService extends CommonsService<PessoaJuridica> {

     getPathModule(): string {
         return 'pessoaJuridica';
     }

  public getAnuidadeAnterior(year: number): Observable<ServerResponse<Profissional>> {
    return this.http.get<ServerResponse<Profissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/anuidade/${year}`);
  }

  public getProfissionaisParaLote(codes: Array<string>, year: string): Observable<ServerResponse<Profissional>> {
    return this.http.post<ServerResponse<Profissional>>(`${this.API_ROOT_URL}/${this.getPathModule()}/paralote`, JSON.parse(stringifyCustom({ code: codes, year: year })));
  }

  public loadContendo(value: string): Observable<ServerResponse<PessoaJuridica>> {
    return this.http.get<ServerResponse<PessoaJuridica>>(`${this.API_ROOT_URL}/${this.getPathModule()}/contendo/${value}`);
  }

  public loadCidades(): Observable<ServerResponse<any>> {
    return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/cidades`);
  }

  public getGeoLocalizacao(endereco: string): Observable<any> {
       const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=AIzaSyA_uzVBPDU7Vf6E6lueZg3qlv2vmJyA7T8`;
       return this.http.get(url);
  }

  public verificarQtdCoordenadorUTI(profissionalId: string): Observable<boolean> {
    return this.http.get<any>(`${this.API_ROOT_URL}/${this.getPathModule()}/${profissionalId}/verificar-qtd-coordenador-uti`);
  }
}
