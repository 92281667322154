import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonsForm} from '../../../commons-form';
import {FilterData} from '../../../components/interfaces';
import {CommonsService} from '../../../commons-service';
import {HistoricoProfissionalService} from '../historicoprofissional.service';
import {ToastrService} from 'ngx-toastr';
import {IndiceService} from '../../indice/indice.service';
import {ScriptLoaderService} from '../../../_services/script-loader.service';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Rod} from "../../rod/rod";
import {LivrotoService} from "../../livroto/livroto.service";
import {LivrofisioterapiaService} from "../../livrofisioterapia/livrofisioterapia.service";
import {Livrofisioterapia} from "../../livrofisioterapia/livrofisioterapia";
import {Livroto} from "../../livroto/livroto";
import {ProfissionalService} from "../../profissional/profissional.service";
import {Profissional} from "../../profissional/profissional";
import {Usuario} from "../../usuario/usuario";
import {LoginResponse} from "../../../security/login-response";
import {PessoaJuridica} from "../../pessoajuridica/pessoajuridica";
import {Consultorio} from "../../consultorio/consultorio";
import {RodService} from "../../rod/rod.service";
import {Historicoprofissional} from '../historicoprofissional';

declare var $:any;


@Component({
  selector: 'gov-asasasas-form',
  templateUrl: './historicoprofissional-form.component.html',
  //styleUrls: ['./historicoProfissional-form.component.css']
})
export class HistoricoProfissionalFormComponent implements OnInit, AfterViewInit {

  @Input() activeEntity: Profissional|PessoaJuridica|Consultorio;
  @Input() entityId: string;
  @Output() onGetHistoricos: EventEmitter<boolean> = new EventEmitter();
  @Output() onGetInscricao: EventEmitter<string> = new EventEmitter();

  auth: LoginResponse;
  activeUser: Usuario;

  formHistorico: FormGroup;
  formHistoricos: Array<FormGroup>;
  formInscricao: FormGroup;
  formInscricoes: Array<FormGroup>;

  formLivroFI: FormGroup;
  formLivroTO: FormGroup;

  historicoAtivo: Historicoprofissional;

  situacoes: Array<{acao: string, values: Array<string>}>;
  rods: Array<Rod> = new Array<Rod>();

  habilitarAgrupamento: boolean = false;

  lastRod: Rod;
  livroFsioterapia: Livrofisioterapia;
  livroTo: Livroto;

  constructor(private apiService: HistoricoProfissionalService,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private rodService: RodService,
              private livrotoService: LivrotoService,
              private livroFisioterapiaService: LivrofisioterapiaService,
              private profissionalService: ProfissionalService,
              public activeModal: NgbActiveModal,
              private indiceService: IndiceService,
              private _script: ScriptLoaderService,
              private toastr: ToastrService,
              protected router: Router) {
    this.auth = JSON.parse(sessionStorage.getItem( "loginResponse"));
    this.situacoes = new Array<{acao: string; values: Array<string>}>();
  }

  ngOnInit() {
    this.buildForms();

    this.activeUser = this.auth.usuario;
    this.situacoes.push({acao: 'Atualiza Situação', values: ['INSCRIÇÃO DEFINITIVA', 'BAIXA', 'INSCRIÇÃO SEC RECEBIDA', 'INSCRIÇÃO SEC EMITIDA', 'TRANSFERÊNCIA EMITIDA', 'TRANSFERÊNCIA RECEBIDA']});
    this.situacoes.push({acao: 'Não Atualiza Situação', values: ['PRORROGAÇÃO', 'PÓS-GRADUAÇÃO', 'APOSTILAMENTO', 'REINSCRIÇÃO', 'ESPECIALIZAÇÃO', '2º VIA']});

    this.getLastRod();
    this.loadRods();
  }

  ngAfterViewInit() {
    this._script.load('./assets/js/scripts/form-plugins.js');
    this._script.load('./assets/js/scripts/toastr-demo.js');
  }

  buildForms() {
    this.formHistorico = this.getFormHistoricoInstance();
    this.formInscricao = this.getFormInscricaoInstance();

    this.buildFormLivros();
    this.buildFormAgrupado();
  }

  getFormHistoricoInstance(): FormGroup {
    return this.fb.group({
      id: [null],
      rod: [null],
      pad: [null],
      data: [null],
      hora: [null],
      nome: [null],
      inscricao: [null],
      historico: [null],
      ativo: [null],
      situacao: [null],
      login: [null],
      profissional_id: [null],
      observacao: [null],
    });
  }

  getFormInscricaoInstance(): FormGroup {
    return this.fb.group({
      inscricao: [null, [Validators.required]],
      dataInscricaoCoffito: [null, [Validators.required]],
      livro: [null, [Validators.required]],
      folha: [null, [Validators.required]]
    });
  }

  buildFormLivros() {
    this.formLivroFI = this.fb.group({
      id: [null],
      numero: [null],
      folha: [null],
      data:[null],
      profissional_id:[null],
      usuario: [null]
    });

    this.formLivroTO = this.fb.group({
      id: [null],
      numero: [null],
      folha: [null],
      data: [null],
      profissional_id: [null],
      usuario: [null]
    });
  }

  buildFormAgrupado() {
    this.formHistoricos = new Array<FormGroup>();
    this.formHistoricos.push(this.formHistorico);

    this.formInscricoes = new Array<FormGroup>();
    this.formInscricoes.push(this.formInscricao);
  }

  getLastRod(){
    this.apiService.getlastRod()
      .subscribe((response) => {
        if(response) {
          this.lastRod = response as unknown as Rod;
        }
      }, (error) => {
        console.log('Ocorreu um erro ao carregar a o rod em aberto.');
      });
  }

  loadRods() {
    this.rodService.loadAll()
      .subscribe(response => {
        if(response) {
          this.rods = response as unknown as Array<Rod>;
          this.formHistorico.get('rod').setValue(this.lastRod.id);
          this.formHistorico.get('rod').disable();
          this.rods = this.rods.sort((r1, r2) => r1.id < r2.id ? 1 : -1);
        }
      }, error => {
        console.log('Ocorreu um erro na tentaiva de carregar as rods: ', error);
      });
  }

  getHistoricoProfissionals(intityId){
    this.apiService.findById(intityId)
      .subscribe(response => {
        if(response) {
          this.historicoAtivo = response as unknown as Historicoprofissional;
          this.formHistorico.patchValue(this.historicoAtivo);
        }
      });
  }

  onSubmitForm() {
    if(this.router.url.includes('pessoajuridica')) {
      this.saveHistoricoPessoaJuridica();
    } else {
      if(!this.habilitarAgrupamento) {
        this.inscreverProfissionalNoLivroESalvar(this.formHistorico, this.formInscricao);
      } else {
        this.formHistoricos.forEach((form, index) => {
          this.inscreverProfissionalNoLivroESalvar(form, this.formInscricoes[index]);
        });
      }
    }
  }

  inscreverProfissionalNoLivroESalvar(formHistorico: FormGroup, formInscricao: FormGroup) {
    const profissional = this.activeEntity as Profissional;
    const tipoHistorico = formHistorico.get('historico').value;
    const tipos = ['INSCRIÇÃO DEFINITIVA', 'INSCRIÇÃO SEC RECEBIDA', 'REINSCRIÇÃO', 'TRANSFERÊNCIA RECEBIDA'];

    if(tipos.includes(tipoHistorico)) {
      if(profissional.graduacao && profissional.graduacao.length && formInscricao.valid) {
        switch (profissional.graduacao) {
          case 'FISIOTERAPIA': {
            const rod = this.rods.find(r => r.id == formHistorico.get('rod').value);

            this.activeEntity['registroCoffito'] = `${formInscricao.get('inscricao').value}-F`;
            this.activeEntity['livroFolhaCoffito'] = formInscricao.get('livro').value;
            this.activeEntity['folhaCoffito'] = formInscricao.get('folha').value;
            this.activeEntity['dataInscricaoCoffito'] = formInscricao.get('dataInscricaoCoffito').value;

            this.formLivroFI.patchValue({
              data: rod.data,
              profissional_id: this.activeEntity.id,
              usuario: this.activeUser.login
            });

            this.livroFisioterapiaService.save(this.formLivroFI.getRawValue())
              .subscribe(response => {
                if(response) {
                  this.livroFsioterapia = response as unknown as Livrofisioterapia;
                  this.toastr.success(`O profissional ${this.activeEntity['nome']} foi registrado na folha ${this.livroFsioterapia.folha} por ${this.livroFsioterapia.usuario}`,  `Registro Livro n°${this.livroFsioterapia.numero}`);

                  this.activeEntity['livroCrefito'] = this.livroFsioterapia.numero;
                  this.activeEntity['folhaCrefito'] = this.livroFsioterapia.folha;

                  this.profissionalService.save(this.activeEntity as Profissional)
                    .subscribe((response) => {
                      this.criarHistoricoProfissional(formHistorico, tipoHistorico);
                    }, error => {
                      console.log('Ocorreu um erro na tentiva de refistro de historioco: ', error);
                    });
                }
              }, error => {
                console.log('Ocorreu um erro na tentativa de increver um profissional no livro: ', error);
              });
            break;
          }
          case 'TERAPIA OCUPACIONAL': {
            const rod = this.rods.find(r => r.id == formHistorico.get('rod').value);

            this.activeEntity['registroCoffito'] = `${formInscricao.get('inscricao').value}-TO`;
            this.activeEntity['livroFolhaCoffito'] = formInscricao.get('livro').value;
            this.activeEntity['folhaCoffito'] = formInscricao.get('folha').value;
            this.activeEntity['dataInscricaoCoffito'] = formInscricao.get('dataInscricaoCoffito').value;

            this.formLivroTO.patchValue({
              data: rod.data,
              profissional_id:this.activeEntity.id,
              usuario: this.activeUser.login
            });

            this.livrotoService.save(this.formLivroTO.getRawValue())
              .subscribe(response => {
                if(response) {
                  this.livroTo = response as unknown as Livroto;
                  this.toastr.success(`O profissional ${this.activeEntity['nome']} foi registrado na folha ${this.livroTo.folha} por ${this.livroTo.usuario}`,  `Registro Livro n°${this.livroTo.numero}`);

                  this.activeEntity['livroCrefito'] = this.livroTo.numero;
                  this.activeEntity['folhaCrefito'] = this.livroTo.folha;

                  this.profissionalService.save(this.activeEntity as Profissional)
                    .subscribe((response) => {
                      this.criarHistoricoProfissional(formHistorico, tipoHistorico);
                    }, error => {
                      console.log('Ocorreu um erro na tentiva de refistro de historioco: ', error);
                    });
                } else {
                  this.toastr.error('Livro não foi salvo.',  'Atenção' );
                }
              });
            break;
          }
          default: {
            this.toastr.error('A graduação informada para profissional é inválida','Atenção');
          }
        }
      } else {
        console.log('É necessário informar o tipo de graduação do profissional.');
        this.toastr.error('É necessário informar o tipo de graduação do profissional.','Atenção');
      }
    } else {
      this.criarHistoricoProfissional(formHistorico, tipoHistorico);
    }
  }

  criarHistoricoProfissional(formHistorico: FormGroup, tipoHistorico: string) {
    const rod = this.rods.find(r => r.id == formHistorico.get('rod').value);

    formHistorico.patchValue({
      profissional_id: this.activeEntity.id,
      login: this.activeUser.nome,
      nome: this.activeEntity.nome,
      inscricao: this.activeEntity['registroCoffito'],
      rod: rod.id,
      data: rod.data,
      historico: tipoHistorico,
      situacao: tipoHistorico
    });

    this.apiService.save(formHistorico.getRawValue())
      .subscribe(response => {
        const result = <any>response;

        if(result == 'FALHA INSCRIÇÃO DEFINITIVA') {
          this.toastr.error('Registro Cofitto, Livro e Folha não cadastrado.','Atenção');
        } else {
          this.modalService.dismissAll();
          this.toastr.success('',  'Historico registrado com sucesso '  );
          this.onGetHistoricos.emit(true);
        }
      }, error1 => {
        console.log(error1);
        this.toastr.error('Erro ao registrar Historico Profissional','Atenção');
      });
  }

  saveHistoricoPessoaJuridica() {
    this.formHistorico.patchValue({
      profissional_id: this.activeEntity.id,
      login: this.activeUser.nome,
      rod: this.lastRod.id,
      data: this.lastRod.data

    });

    this.apiService.savehistoricopj(this.formHistorico.value)
      .subscribe(response => {
        if(response) {
          this.toastr.success('',  'Historico registrado com sucesso '  );
          this.modalService.dismissAll();
          const nav = this.router.navigate(['/pessoajuridica/'+this.activeEntity.id]);
        }
      }, error => {
        console.log('Ocorreu um erro ao tentaar registra o historico de empresa: ', error);
        this.toastr.error('Erro ao registrar Historico de Pessoa Jurídica!','Atenção');
      });
  }

  saveLivroFisio() {
    this.formLivroFI.patchValue({
      data: this.lastRod.data,
      profissional_id: this.activeEntity.id,
      usuario: this.activeUser.login
    });

    this.livroFisioterapiaService.save(this.formLivroFI.getRawValue())
      .subscribe(response => {
        this.livroFsioterapia = (<any>response);
        this.toastr.success('',  'Livro salvo com sucesso' );
      });
  }

  checkHistorico(tipo: string): boolean {
    if(tipo && tipo.length) {
      return ['INSCRIÇÃO DEFINITIVA', 'INSCRIÇÃO SEC RECEBIDA', 'TRANSFERÊNCIA RECEBIDA', 'REINSCRIÇÃO'].includes(tipo);
    }
    return false;
  }

  onChangeAgrupamento(event) {
    const checked = event.target.checked;
    if(!checked) {
      this.buildFormAgrupado();
      this.formHistorico.get('rod').setValue(this.lastRod.id);
      this.formHistorico.get('rod').disable();
    } else {
      this.buildForms();
    }
  }

  onClickAdicionar() {
    this.formHistoricos.push(this.getFormHistoricoInstance());
    this.formInscricoes.push(this.getFormInscricaoInstance());
  }

  onClickRemoveForm(index: number) {
    if(this.formHistoricos.length > 1) {
      this.formHistoricos.splice(index, 1);
      this.formInscricoes.splice(index, 1);
    } else {
      this.formHistoricos.splice(index, 1);
      this.formInscricoes.splice(index, 1);
      this.habilitarAgrupamento = false;

      this.buildFormAgrupado();
      this.formHistorico.get('rod').setValue(this.lastRod.id);
      this.formHistorico.get('rod').disable();
    }
  }
}

