import { Injectable } from '@angular/core';
import {CommonsService} from "../../commons-service";
import {AutoRdc} from "./auto-rdc";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AutoRdcService extends CommonsService<AutoRdc> {

  constructor(public http: HttpClient) { super(http) }

  getPathModule(): string {
    return "autordc";
  }
}
