import { Component, OnInit } from '@angular/core';
import {SolicitacaoCertidaoService} from "../solicitacao-certidao.service";
import {SharedService} from "../../../shared/shared.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {SolicitacaoCertidao} from "../solicitacao-certidao";
import {ProfissionalService} from "../../profissional/profissional.service";
import {ToastrService} from "ngx-toastr";
import {Profissional} from "../../profissional/profissional";

@Component({
  selector: 'app-solicitacao-certidao-validacao',
  templateUrl: './solicitacao-certidao-validacao.component.html',
})
export class SolicitacaoCertidaoValidacaoComponent implements OnInit {

  referencia: string;
  beanId: string;
  hashCode: string;
  solicitacao: SolicitacaoCertidao;

  constructor(public apiService: SolicitacaoCertidaoService,
              public router: Router,
              public route: ActivatedRoute,
              public toastService: ToastrService,
              public spinnerService: NgxSpinnerService,
              public sharedService: SharedService) { }

  ngOnInit() {
    this.spinnerService.show();
    this.route.params.subscribe(params => {
      console.log(this.route);
      this.beanId = params['id'];
      this.hashCode = params['hashcode'];

      this.checkHash();
    });
  }

  checkHash(): void {
    this.apiService.findByHashCode(this.hashCode).subscribe(response => {
      console.log(response);
      this.solicitacao = <any>response;

      if(this.solicitacao) {
        this.toastService.success(`${this.solicitacao.nomeDocumento} é Válida.`, 'Sucesso', {timeOut: 5000, progressBar: true})

        if(this.solicitacao.nomeDocumento == 'CERTIDÃO NADA CONSTA FINANCEIRO') {
          this.sharedService.printReport('CertidaoNadaConsta', this.beanId);

        } else {
          alert('ESSE RELATÓRIO AINDA NÃO ESTA DISPONÍVEL');
        }
      }

      this.spinnerService.hide();

    }, error => {
      this.spinnerService.hide();
      console.log(error);
    });
  }
}
