import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProcessoAdministrativoService} from "../processo-administrativo.service";
import {FaseProcessoAdministrativo} from "../../fase-processo-administrativo/fase-processo-administrativo";
import {ToastrService} from "ngx-toastr";
import {NgxSmartModalService} from "ngx-smart-modal";
import {Usuario} from "../../usuario/usuario";
import {SharedService} from "../../../shared/shared.service";
import {ProcessoAdministrativo} from "../processo-administrativo";
import {NgxSpinnerService} from "ngx-spinner";
import * as moment from "moment";
import {Guia} from "../../guia/guia";
import {FilterDataHql} from "../../../shared/filterdata/filter-data-hql";
import {ColumnType} from "../../../shared/filterdata/column-type.enum";
import {ColumnMatch} from "../../../shared/filterdata/column-match.enum";

@Component({
  selector: '[processo-administrativo-fase-modal]',
  templateUrl: './processo-administrativo-fase-modal.component.html',
  styleUrls: ['./processo-administrativo-fase-modal.component.scss']
})
export class ProcessoAdministrativoFaseModalComponent implements OnInit {

  processoADM: ProcessoAdministrativo = {} as ProcessoAdministrativo;
  atualFase: FaseProcessoAdministrativo = {} as FaseProcessoAdministrativo;
  viewFase: boolean = false;
  newFase: boolean = false;

  @Input() logado: Usuario;
  @Output() emitterNovaFase: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public processoAdmService: ProcessoAdministrativoService,
              private toastr: ToastrService,
              private sharedService: SharedService,
              private spinner: NgxSpinnerService,
              public ngxModal: NgxSmartModalService) { }

  ngOnInit() {
  }

  public onClickActionFase(type: string) {
    if(type == 'add') {
      this.atualFase.emissor = this.logado.nome;
      this.atualFase.data = new Date();
      this.atualFase.situacao = 'EM ANDAMENTO'
      this.processoADM.fases.push(this.atualFase);
      this.registrarPA();
    } else if(type == 'remove') {
      const i = this.processoADM.fases.findIndex(f => f['id'] == this.atualFase['id']);
      if(i > -1) {
        this.processoADM.fases.splice(i, 1);
        this.registrarPA();
      } else {
        this.toastr.info('Essa fase já foi excluída, por favor atualize a tela.', 'Atenção!');
      }
    } else if (type == 'print') {
      const filtro = {whereClauses: [{columnName: 'pa.id', iniValue: this.processoADM.id, columnType: ColumnType.LONG, columnMatch: ColumnMatch.EQUALS}]} as FilterDataHql;
      this.processoAdmService.emitirRelatorio('TermoInscricaoDividaAtivaProfissional', filtro)
          .subscribe((res) => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL);
          });
    }
  }

  public registrarPA() {
    this.spinner.show()
    this.processoAdmService.save(this.processoADM)
      .subscribe(response => {
        if(response) {
          this.toastr.success('Nova fase adicionada ao processo nº '.concat(this.processoADM.numero), 'Sucesso', {closeButton: true, progressBar: true, timeOut: 2500});
          this.ngxModal.closeAll();
          this.emitterNovaFase.emit(true);
        }
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.toastr.warning('Fase não criada', 'Atenção', {closeButton: true, progressBar: true, timeOut: 2500});
        this.toastr.error('Erro: '.concat(error.message), 'Atenção', {closeButton: true, progressBar: true, timeOut: 2500});
        console.log("Ocorreu um erro: ".concat(error.message));
      });
  }

  public onChangeValues(event, type: string) {
    switch (type) {
      case 'prazo': {
        const prazo = this.atualFase.prazo;
        if(prazo) {
          this.atualFase.validade = moment().add(prazo, 'days').toDate();
        }
        break;
      }

      case 'fase': {
        switch (this.atualFase.name) {
          case 'ABERTURA - NOTIFICAÇÃO DE COBRANÇA': {
            this.atualFase.fase = '1';
            break;
          }

          case 'DESPACHO': {
            this.atualFase.fase = '2';
            break;
          }

          case 'ENVIO À GRÁFICA':
          case 'AR POSTADO':
          case 'AR ENTREGUE':
          case 'AR DEVOLVIDO': {
            this.atualFase.fase = '3';
            break;
          }

          case 'COBRANÇA INDEVIDA':
          case 'FINALIZADO POR PAGAMENTO': {
            this.atualFase.fase = '4';
            break;
          }

          case 'NOTIFICAÇÃO POR EDITAL': {
            this.atualFase.fase = '5';
            break;
          }

          case 'INSCRIÇÃO EM DÍVIDA ATIVA':
          case 'EMISSÃO DA CDA': {
            this.atualFase.fase = '6';
            break;
          }

          default: {
            break;
          }
        }
      }
    }
  }

  public alterarNumeroProcesso(): void {
    this.processoAdmService.save(this.processoADM)
      .subscribe(response => {
        if(response) {
          this.toastr.success('O número do processo foi alterado.', 'Tudo Certo!');
        }
      }, error => {
        console.log("Ocorreu um erro.")
      });
  }

  //TODO - Atenção ao adicionar nova fase, favor incluir no array e tratar no método onChangeValues do tipo [fase]
  public getFases(): Array<{label: string, value: string}> {
    return this.processoAdmService.getFases();
  }

  public onAnyClose() {
    this.processoADM = {} as ProcessoAdministrativo;
    this.atualFase = {} as FaseProcessoAdministrativo;
    this.newFase = false;
  }

  onOpen() {
    this.atualFase.emissor = this.logado.nome;
    this.atualFase.data = moment().toDate();
  }

  getGuiasOriginais(): Array<Guia> {
    if(this.processoADM && this.processoADM.guias && this.processoADM.guias.length) {
      return this.processoADM.guias
        .filter(g => g.tipocobranca_id.id.toString() != '56')
        .sort((g1, g2) => g1.id > g2.id ? 1 : -1);
    }
    return [];
  }
}
