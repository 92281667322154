import {Component, OnInit} from '@angular/core';
import {OcorrenciaService} from "../ocorrencia.service";


@Component({
  selector: 'gov-ocorrencia-view',
  templateUrl: './ocorrencia-view.component.html',
  //styleUrls: ['./ocorrencia-form.component.css']
})
export class OcorrenciaViewComponent implements OnInit {

  constructor(public ocorreciaService: OcorrenciaService) {
  }

  ngOnInit(): void {
  }


}

