import {Component, Input ,OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FilterData} from '../../../components/interfaces';
import {FieldSearch} from '../../../utils/utils';
 import {CommonsGrid} from '../../../commons-grid';
import {ReportGroup} from '../../../shared/report-group';
import {HistoricoProfissionalService} from '../historicoprofissional.service';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HistoricoProfissionalFormComponent} from "../historicoprofissional-form/historicoprofissional-form.component";
import { Subject } from 'rxjs';
import {HistoricoProfissionalViewComponent} from "../historicoprofissional-view/historicoprofissional-view.component";
import {Historicoprofissional} from "../historicoprofissional";

declare var $:any;


@Component({
  selector: 'app-historicoprofissional-grid',
  templateUrl: './historicoprofissional-grid.component.html',
  //styleUrls: ['./historicoProfissional-grid.component.css']
})
export class HistoricoProfissionalGridComponent extends CommonsGrid<Historicoprofissional> implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Historicoprofissional> = new Subject();

  historicoprofissionals: Historicoprofissional;
  @Input() activeEntity;

  beanSubscribeCustom: any;
  beanIdCustom: any;

     constructor(private modalService:NgbModal,
                 public activeModal: NgbActiveModal,
                 apiService: HistoricoProfissionalService,
                 public routeCustom: ActivatedRoute,
                 router: Router) {
         super(apiService, router);
     }
     ngOnInit() {

       this.beanSubscribeCustom =  this.routeCustom.params.subscribe(params => {
         this.beanIdCustom = params['id'];
       });

         this.loadByID(this.activeEntity.id);

       this.dtOptions = {
         pagingType: 'full_numbers',
         retrieve: true,
         pageLength: 5,
         language: {
           emptyTable: "Nenhum registro encontrado",
           info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
           infoEmpty: "Mostrando 0 até 0 de 0 registros",
           infoFiltered: "(Filtrados de _MAX_ registros)",
           infoPostFix: "",
           thousands: ".",
           lengthMenu: "_MENU_ resultados por página",
           loadingRecords: "Carregando...",
           processing: "Processando...",
           zeroRecords: "Nenhum registro encontrado",
           search: "Pesquisar",
           paginate: {
             next: "Próximo",
             previous: "Anterior",
             first: "Primeiro",
             last: "Último"
           },
           aria: {
             sortAscending: ": Ordenar colunas de forma ascendente",
             sortDescending: ": Ordenar colunas de forma descendente"
           }
         }
       };

     }

  loadByID(id) {

           this.apiService.findByentiadeId(id).subscribe(response => {
          this.historicoprofissionals = <any>response;
             this.dtTrigger.next();
            console.log(this.historicoprofissionals);

        });
    }

     getDefaultFilter(): FilterData {
         const filterData = super.getDefaultFilter();
         filterData.order = 'HistoricoProfissional.bairro, HistoricoProfissional.banco, HistoricoProfissional.bancoHistoricoProfissional, HistoricoProfissional.bancoconta, HistoricoProfissional.cidade, HistoricoProfissional.cnpj, HistoricoProfissional.contato, HistoricoProfissional.cpf, HistoricoProfissional.datacadastro, HistoricoProfissional.email, HistoricoProfissional.email1, HistoricoProfissional.email2, HistoricoProfissional.emailcontato, HistoricoProfissional.endereco, HistoricoProfissional.fax, HistoricoProfissional.inscricaoestadual, HistoricoProfissional.inscricaomunicipal, HistoricoProfissional.nomefantasia, HistoricoProfissional.obs, HistoricoProfissional.razaosocial, HistoricoProfissional.site, HistoricoProfissional.telefone, HistoricoProfissional.telefone2 '
         return filterData;
     }

     getRowFilter(): FilterData {
         let filter= this.buildRowFilter('trb_HistoricoProfissional');
         filter.whereClauses[0].dataType='INTEGER';
         return filter;
     }

     getTableName(): string {
         return 'HistoricoProfissional' ;
     }

     getFieldList(): FieldSearch[] {
         let retorno = [];
         retorno[0] = new FieldSearch('bairro','bairro','STRING');
         retorno[1] = new FieldSearch('banco','banco','STRING');
         retorno[2] = new FieldSearch('bancoHistoricoProfissional','bancoHistoricoProfissional','STRING');
         retorno[3] = new FieldSearch('bancoconta','bancoconta','STRING');
         retorno[4] = new FieldSearch('cidade','cidade','STRING');
         retorno[5] = new FieldSearch('cnpj','cnpj','STRING');
         retorno[6] = new FieldSearch('contato','contato','STRING');
         retorno[7] = new FieldSearch('cpf','cpf','STRING');
         retorno[8] = new FieldSearch('datacadastro','datacadastro','DATE');
         retorno[9] = new FieldSearch('email','email','STRING');
         retorno[10] = new FieldSearch('email1','email1','STRING');
         retorno[11] = new FieldSearch('email2','email2','STRING');
         retorno[12] = new FieldSearch('emailcontato','emailcontato','STRING');
         retorno[13] = new FieldSearch('endereco','endereco','STRING');
         retorno[14] = new FieldSearch('fax','fax','STRING');
         retorno[15] = new FieldSearch('inscricaoestadual','inscricaoestadual','STRING');
         retorno[16] = new FieldSearch('inscricaomunicipal','inscricaomunicipal','STRING');
         retorno[17] = new FieldSearch('nomefantasia','nomefantasia','STRING');
         retorno[18] = new FieldSearch('obs','obs','STRING');
         retorno[19] = new FieldSearch('razaosocial','razaosocial','STRING');
         retorno[20] = new FieldSearch('site','site','STRING');
         retorno[21] = new FieldSearch('telefone','telefone','STRING');
         retorno[22] = new FieldSearch('telefone2','telefone2','STRING');
         return retorno;
     }

     getReportList():ReportGroup[]{return [];}

  ngAfterViewInit() {

  }

  openNovaHistoricoProfissionalModal() {

    const modalNovaHistoricoProfissional =  this.modalService.open( HistoricoProfissionalFormComponent, {
      size: 'lg'
    });

    modalNovaHistoricoProfissional.componentInstance.activeEntity = this.activeEntity ;
    modalNovaHistoricoProfissional.result.then((result) => {
      this.dtOptions.destroy;
      this.ngOnInit();
    } );

  }

  openViewHistoricoProfissionalModal(historicoprofissionalView) {

    const modalViewHistoricoProfissional =  this.modalService.open(HistoricoProfissionalViewComponent, {
      size: 'lg'
    });

    modalViewHistoricoProfissional.componentInstance.historicoprofissionalView = historicoprofissionalView ;

  }


  removeHistoricoProfissional(id){
    this.apiService.delete(id).subscribe(response => {
      console.log(response);
      this.ngOnInit();
    });

  }

}
