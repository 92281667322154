import {Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Helpers} from "./helpers";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {Profissional} from "./entidades/profissional/profissional";
import {IUserResponse} from "./utils/profiss.class";
import { SearchService } from "./search.service";
import {DataTableDirective} from "angular-datatables";
import {ProfissionalService} from "./entidades/profissional/profissional.service";
import {ToastrService} from "ngx-toastr";
import Swal from "sweetalert2";
import {PessoaJuridicaService} from "./entidades/pessoajuridica/pessoajuridica.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ConsultorioService} from "./entidades/consultorio/consultorio.service";

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {

  filteredUsers: Observable<IUserResponse>;
  usersForm: FormGroup;
  selectProfisData: Profissional;

  profissionais: {};
  entidades: Array<any>;
  erros: any;
  buscaForm: FormGroup;

  constructor(private fb: FormBuilder,
              private router: Router,
              private profissionalService: ProfissionalService,
              private consultorioService: ConsultorioService,
              private pessaojuridicaService: PessoaJuridicaService,
              private appService: SearchService ,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
    private apiService: ProfissionalService,
    private _router: Router) {}

  ngOnInit() {
    this.buildForm();

    this.usersForm = this.fb.group({
      userInput: null
    })

    this.filteredUsers = this.usersForm.get('userInput').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.appService.search({nome: value}, 1))
      );

    this._router.events.subscribe((route) => {
			if (route instanceof NavigationStart) {
				Helpers.setLoading(true);
				Helpers.bodyClass('fixed-navbar');
			}
			if (route instanceof NavigationEnd) {
				window.scrollTo(0, 0);
				Helpers.setLoading(false);

				// Initialize page: handlers ...
				Helpers.initPage();
			}

		});
  }

  ngAfterViewInit(): void {}

  buildForm() {
    this.buscaForm = this.fb.group({
      campo: "nome",
      valor: [''],
      tipo: [null],
    });
  }

  goTo(id){

    if(this.buscaForm.get('tipo').value == 'profissional' ){
      this.router.navigate(['/profissional/'+id]);
    }
    if(this.buscaForm.get('tipo').value == 'pessoaJuridica' ){
      this.router.navigate(['/pessoajuridica/'+id]);
    }
    if(this.buscaForm.get('tipo').value == 'consultorio' ){
      this.router.navigate(['/consultorio/'+id]);
    }
  }

  remove(event, id: string, nome: string) {
    event.stopPropagation();
    const s = Swal.fire({
      title: 'Atenção',
      icon: 'warning',
      text: `Essa ação removerá irreversivelmente o cadastro de ${nome} com ID: ${id}, deseja prosseguir ? `,
      cancelButtonText: 'Não',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Sim',
    }).then((result) => {
      if(result.value) {
        this.spinner.show();
        if(this.buscaForm.get('tipo').value == 'profissional') {
          this.profissionalService.delete(id).subscribe(response => {
            this.spinner.hide();
            this.toastr.success('O item foi removido com sucesso.', 'Tudo Certo!');
            this.buscar();
          }, error => {
            this.spinner.hide();
            this.toastr.error('Ooorreu um erro na tentativa de remoção do item.', 'Erro');
            console.log(error);
          });
        }

        if(this.buscaForm.get('tipo').value == 'pessoaJuridica' ){
          this.pessaojuridicaService.delete(id).subscribe(response => {
            this.spinner.hide();
            this.toastr.success('O item foi removido com sucesso.', 'Tudo Certo!');
            this.buscar();
          }, error => {
            this.spinner.hide();
            this.toastr.error('Ooorreu um erro na tentativa de remoção do item.', 'Erro');
            console.log(error);
          });
        }

        if(this.buscaForm.get('tipo').value == 'consultorio') {
          this.consultorioService.delete(id).subscribe(response => {
            this.spinner.hide();
            this.toastr.success('O item foi removido com sucesso.', 'Tudo Certo!');
            this.buscar();
          }, error => {
            this.spinner.hide();
            this.toastr.error('Ooorreu um erro na tentativa de remoção do item.', 'Erro');
            console.log(error);
          });
        }
      }
    });
  }

  closeShined(id) {
    this.goTo(id);
    $('body').removeClass('has-backdrop');
    $('.shined').removeClass('shined');
  }

  closeShinedErro() {
    $('body').removeClass('has-backdrop');
    $('.shined').removeClass('shined');
  }

  buscar(){
    if(this.buscaForm.get('tipo').value != null) {
      this.apiService.busca(this.buscaForm.value).subscribe(response => {
        console.log(response);
        this.entidades = response as unknown as Array<any>;
      }, (err) => {
        console.log(err);
      });
    } else {
      this.toastr.error('É necessário informar um tipo de pesquisa.', 'Atenção');
    }
  }

  onClickClose() {
    this.buildForm();
    this.entidades = [];
  }

  onChangeTipo() {
    this.entidades = new Array<any>();
  }
}
