import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {DataTablesModule} from 'angular-datatables';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {CURRENCY_MASK_CONFIG, CurrencyMaskConfig} from "ng2-currency-mask/src/currency-mask.config";

import {MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {NgxSpinnerModule} from "ngx-spinner";
import {IConfig, NgxMaskModule} from 'ngx-mask'
import {AppComponent} from './/app.component';
import {AppRoutingModule} from './/app-routing.module';
import {LayoutModule} from './/layouts/layout.module';
import {ScriptLoaderService} from './_services/script-loader.service';

import {SharedModule} from "./shared/shared.module";
import {OcorrenciaModule} from "./entidades/ocorrencia/ocorrencia.module";
import {SearchService} from "./search.service";
import {HistoricoProfissionalModule} from "./entidades/historicoprofissional/historicoprofissional.module";
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {NgbModalModule} from "@ng-bootstrap/ng-bootstrap";
import {SolicitacaoCertidaoValidacaoComponent} from './entidades/solicitacao-certidao/solicitacao-certidao-validacao/solicitacao-certidao-validacao.component';
import {ClipboardModule} from "ngx-clipboard";
import {RelatorioComponent} from './pages/relatorio/relatorio.component';
import {NgxSmartModalModule} from "ngx-smart-modal";


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};


registerLocaleData(localePt, 'pt-BR');


@NgModule({
  declarations: [
    AppComponent,
    SolicitacaoCertidaoValidacaoComponent,
    RelatorioComponent,
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    DataTablesModule,
    FormsModule,
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    OcorrenciaModule,
    HistoricoProfissionalModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), // ToastrModule added
    // AgmCoreModule.forRoot({apiKey: 'AIzaSyDSitGzMbgPxrW_TxSYwtrkzyH4lB7XqAQ'}),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    CurrencyMaskModule,
    NgbModalModule,
    ClipboardModule,
    NgxSmartModalModule,
  ],
  providers: [SearchService, ScriptLoaderService, {
    provide: LOCALE_ID,
    useValue: 'pt-BR'
  }, {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig},],
  bootstrap: [AppComponent],
})
export class AppModule {
}
