import {CommonsService, ServerResponse} from '../../commons-service';
import {Injectable} from "@angular/core";
import { Boleto} from './boleto'
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' }) 
export class BoletoService extends CommonsService<Boleto> {

     getPathModule(): string { 
         return 'boleto';
     }

     findByCobranca(id: string): Observable<ServerResponse<any>> {
       return this.http.get<ServerResponse<any>>(`${this.API_ROOT_URL}/${this.getPathModule()}/cobranca/${id}`);
     }
} 
